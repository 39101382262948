/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import './CssVariables';


@font-face {
    font-family: "SourceSansProRegular";
    src: url("assets/fonts/SourceSansPro-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "SourceSansProSemiBold";
    src: url("assets/fonts/SourceSansPro-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "SourceSansProBold";
    src: url("assets/fonts/SourceSansPro-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "PFHandbookProRegular";
    src: url("assets/fonts/PFHandbookProRegular.ttf") format("truetype");
}

@font-face {
    font-family: "PFHandbookProSemiBold";
    src: url("assets/fonts/PFHandbookProMedium.ttf") format("truetype");
}

@font-face {
    font-family: "PFHandbookProBold";
    src: url("assets/fonts/PFHandbookProBold.ttf") format("truetype");
}
@font-face {
    font-family: "SegoeUIRegular";
    src: url("assets/fonts/Segoe UI.ttf") format("truetype");
  }
  @font-face {
    font-family: "SegoeUISemiBold";
    src: url("assets/fonts/Segoe UI Semibold.ttf") format("truetype"),
  }
  @font-face {
    font-family: "SegoeUIBold";
    src: url("assets/fonts/Segoe UI Bold.ttf") format("truetype");
  }
  @font-face {
    font-family: "OpenSansRegular";
    src: url("assets/fonts/OpenSans-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: "OpenSansMedium";
    src: url("assets/fonts/OpenSans-Medium.ttf") format("truetype");
  }
  @font-face {
    font-family: "OpenSansBold";
    src: url("assets/fonts/OpenSans-Bold.ttf") format("truetype");
  }
  @font-face {
    font-family: "OpenSansSemiBold";
    src: url("assets/fonts/OpenSans-SemiBold.ttf") format("truetype");
  }
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

*[disabled] {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
}

body {
    scroll-behavior: smooth;
    font-family: $fontFamilyRegular;
    color: $body_color;
    font-size: 15px;
    //background-color: var(--body-bg-color);
    // background: -moz-linear-gradient(20deg, rgba(250, 200, 199, 1) 0%, rgba(227, 244, 255, 1) 100%);
    // background: -webkit-linear-gradient(20deg, rgba(250, 200, 199, 1) 0%, rgba(227, 244, 255, 1) 100%);
    // background: linear-gradient(20deg, rgba(250, 200, 199, 1) 0%, rgba(227, 244, 255, 1) 100%);
    // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fac8c7", endColorstr="#e3f4ff", GradientType=1);
    background: #eef6fb;
    // background: -moz-linear-gradient(90deg, rgba(254, 244, 244, 1) 0%, rgba(239, 246, 250, 1) 45%, rgba(238, 246, 251, 1) 100%);
    // background: -webkit-linear-gradient(90deg, rgba(254, 244, 244, 1) 0%, rgba(239, 246, 250, 1) 45%, rgba(238, 246, 251, 1) 100%);
    // background: linear-gradient(90deg, rgba(254, 244, 244, 1) 0%, rgba(239, 246, 250, 1) 45%, rgba(238, 246, 251, 1) 100%);
    background-attachment: fixed;
}
.Main_Alankit {
    body {
        font-size: 14px !important; 
    }
}
a {
    color: $body_color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $secondary_color;
}

.BoxShadow {
    box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
}

.MainBg {
    background-color: $body_bg_color; //#e7e9eb;
    display: inline-block;
    width: 100%;
    min-height: calc(100vh - 112px);
}

.CommonTextBox,
.CommonSelectBox {
    border: none !important;
    border-bottom: 1px solid #eaeaea !important;
    border-radius: 0 !important;
    font-size: 14px !important;
    color: $body_color !important;
    padding: 8px 0px !important;
    height: 38px !important;
    margin-bottom: 0px !important;

    // &:focus {
    //     border: none !important;
    //     border-bottom: 1px solid #eaeaea !important;
    //     box-shadow: none !important;
    // }

}

.SPATextBox,
.CommonSelectBox {
    border: 1px solid #eaeaea !important;
    border-radius: 0 !important;
    font-size: 14px !important;
    color: $body_color !important;
    padding: 8px 0px 0px 8px !important;
    height: 38px !important;
    margin-bottom: 0px !important;

    &:focus {
        border: 1px solid #eaeaea !important;
        box-shadow: none !important;
    }

}

.has-float-label input:placeholder-shown:not(:focus)+*,
.has-float-label select:placeholder-shown:not(:focus)+*,
.has-float-label textarea:placeholder-shown:not(:focus)+* {
    font-size: 14px !important;
    opacity: .5;
    top: 0.7em !important;
}

.has-float-label label {
    top: -14px !important;
    font-size: 12px !important;
    color: $body_color_gray;
}

.has-float-label>span {
    position: inherit !important;
}

.CommonTextBox.TbxArnPrefix {
    padding-left: 35px !important;
}

.CommonTextBox.TbxEPrefix {
    padding-left: 9px !important;
}

/* Custom Select Box*/

.select2-container--default .select2-selection--single {
    border: none;
    border-bottom: 1px solid #eaeaea;
    border-radius: 0;
    font-size: 14px;
    color: $body_color !important;
    padding: 0px;
    height: 38px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding: 0;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: inherit;
    line-height: 38px;
}

.select2-container {
    display: block !important;
}

select.d-none~.select2-container {
    display: none !important;
}

.OnboardingPreviewDoc .select2-container {
    min-width: 206.66px !important;
    max-width: 206.66px !important;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: $primary_color !important;
    color: white;
}

.select2-container--default .select2-search--dropdown .select2-search__field,
.select2-dropdown {
    border: 1px solid #eaeaea;
    border-radius: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 17px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    background-image: url(./assets/images/DownArrow.svg);
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    border: none !important;
    height: 10px !important;
    width: 10px !important;
    margin: auto !important;
    top: auto !important;
    left: auto !important;
    transition: all 0.4s;
}

.select2-container--open.select2-container--default .select2-selection--single .select2-selection__arrow b {
    transform: rotate(180deg);
}

.select2-container--default .select2-results>.select2-results__options {
    max-height: 163px !important;
}

select option:hover {
    border: none !important;
}

.dropdown .dropdown-item.active,
.dropdown .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: $primary_color !important;
}

input:focus,
textarea:focus,
select:focus {
    outline: none !important;
}

.InputBoxOuter {
    margin-bottom: 26px;
}

.Signup .InputBoxOuter {
    margin-bottom: 31px !important;
}

.InputBoxOuter {
    position: relative;

    .InfoIcon {
        width: 15px;
        position: absolute;
        top: 12px;
        right: 5px;
        cursor: pointer;
        z-index: 4;
    }

    .CalanderIcon {
        position: absolute;
        top: 10px;
        right: 20px;
        cursor: pointer;
    }

    .CheckAgree label {
        font-size: 14px !important;
    }

    .Cbx_container input:checked~label {
        color: $primary_color;
    }
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #ccc !important;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #ccc !important;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #ccc !important;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #ccc !important;
}

.RedPlaceHolder::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #ff0000 !important;
}

.RedPlaceHolder::-moz-placeholder {
    /* Firefox 19+ */
    color: #ff0000 !important;
}

.RedPlaceHolder:-ms-input-placeholder {
    /* IE 10+ */
    color: #ff0000 !important;
}

.RedPlaceHolder:-moz-placeholder {
    /* Firefox 18- */
    color: #ff0000 !important;
}

.ColorBlue::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $primary_color !important;
}

.ColorBlue::-moz-placeholder {
    /* Firefox 19+ */
    color: $primary_color !important;
}

.ColorBlue:-ms-input-placeholder {
    /* IE 10+ */
    color: $primary_color !important;
}

.ColorBlue:-moz-placeholder {
    /* Firefox 18- */
    color: $primary_color !important;
}

.ShowPasswordOuter {
    position: absolute;
    right: 25px;
    top: 15px;
    cursor: pointer;
}

/*Login and OnBoarding*/
.OnBoardingFormOuter {
    padding-left: 10%;
    margin-top: 110px;

}

.FormHeading {
    min-height: 110px;
    display: flex;
    justify-content: space-between;

    p {
        font-size: 22px;
        font-family: $fontFamilyBold;
        line-height: 25px;
        color: #000;
        margin-bottom: 13px;
    }

    span {
        font-size: 15px;
        line-height: 24px;
        font-family: $fontFamilyBold;
    }
}

.SectionHeading {
    font-size: 22px;
    font-family: $fontFamilyBold;
    line-height: 25px;
    color: $secondary_color;
    margin-bottom: 20px;
}

.SectionHeadText {
    font-size: 15px;
    line-height: 24px;
    color: $body_color_gray;
}

.SubmitMarginTop {
    margin-top: 10px;
}

.SubmitMarginTop.BtnNear {
    margin-top: 90px;
}

.CommonSubmitButton {
    min-width: 160px;
    height: 42px;
    border-radius: 4px;
    background-color: $primary_color;
    color: #fff;
    border: none;
    cursor: pointer;
    font-family: $fontFamilyBold;
    font-size: 17px;
    line-height: 24px;
    padding: 0 20px;

    &:focus {
        border: none;
        outline: none;
    }

    &:disabled {
        background-color: #ddd !important;
        opacity: 1 !important;
    }
}
.Main_Alankit{
    .CommonSubmitButton {
        line-height: 21px !important;
    }
}
.CommonSecondaryButton {
    min-width: 160px !important;
    height: 42px !important;
    border-radius: 4px !important;
    background-color: #fff !important;
    color: $primary_color !important;
    border: 1px solid $primary_color !important;
    cursor: pointer !important;
    font-family: $fontFamilyBold !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 0 20px !important;

    &:disabled {
        background-color: rgb(206, 204, 204) !important;
        color: #fff;
        border: none;
        opacity: 1 !important;
    }
}

.CommonSubmitButton.w-auto,
.CommonSecondaryButton.w-auto {
    min-width: auto !important;
}

.AlertModalFooter .CommonSecondaryButton {
    height: 30px !important;
    min-width: 83px !important;
    border-radius: 4px !important;
    background-color: #fff !important;
    color: $primary_color !important;
    border: 1px solid $primary_color !important;
    cursor: pointer !important;
    font-family: $fontFamilyBold !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 0 20px !important;

    &:disabled {
        background-color: rgb(206, 204, 204) !important;
        color: #fff;
        border: none;
        opacity: 1 !important;
    }
}

.CommonSubmitButton.sm {
    width: 115px !important;
    height: 33px !important;
    font-size: 14px !important;
}

.CommonSecondaryButton.sm {
    width: 115px !important;
    height: 33px !important;
    font-size: 14px !important;
}

.BlueText {
    color: $primary_color !important;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

.ColorBlue {
    color: $primary_color !important;
}

.ColorRed {
    color: #ff0000 !important;
}

.BgRed {
    background-color: rgba(255, 0, 0, 0.20) !important;
}

.Bg-White {
    background-color: #fff;
}

.ColorGreen {
    color: #3A7517 !important;
}

.BgGreen {
    background-color: rgba(22, 154, 38, 0.20);
}

.ColorBlack {
    color: #000;
}

.ColorYellow {
    color: #F9A63A;
}

.CursorPointer {
    cursor: pointer;
}

.PrefixText {
    position: absolute;
    top: 8px;
    left: 21px;
}

.CheckAgree {
    display: flex;

    input[type=checkbox] {
        margin-right: 10px;
    }

    label {
        font-size: 12px;
        margin-bottom: 0;
        line-height: 17px;
        position: relative;

    }

    .Cbx_container {
        display: inline-flex;
    }
}

/* Hide the browser's default checkbox */
.Cbx_container input {
    cursor: pointer !important;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background: #fff;
    background-image: url("./assets/images/Unchecked.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer !important;
}

.RadioBtn .checkmark {
    background-image: url("./assets/images/RadioBtn_Unchecked.svg") !important;
}

/* When the checkbox is checked, add a blue background */
.Cbx_container input:checked~.checkmark {
    // background-color: #fff;
    // background-image: url("./assets/images/CheckBox.svg");
    // background-repeat: no-repeat;
    // background-position: center;
    background-color: $primary_color;
    background-image: none;
}

.Cbx_container.RadioBtn input:checked~.checkmark {
    //background-image: url("./assets/images/RadioBtn_Checked.svg") !important;
    background-color: $primary_color;
    background-image: none !important;
    border-radius: 50%;
}

.Cbx_container input~label {
    margin-left: 10px !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.Cbx_container input:checked~.checkmark:after {
    display: block;
    left: 5.5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.Cbx_container.RadioBtn input:checked~.checkmark:after {
    display: block;
    left: 4px;
    top: 4px;
    width: 4px;
    height: 7px;
    border: solid white;
    border-width: 2px 4px;
    transform: none;
    border-radius: 50%;
}



/*Profile*/
.MainInnerForm {
    background-color: #fff;
    margin: 88px 20px 20px;
    display: flex;
    @extend .BoxShadow;
}

.MainInnerFormWithoutFlex {
    background-color: #fff;
    margin: 88px 20px 20px;
    @extend .BoxShadow;
}

.SinglePageOuter {
    padding: 20px;
}

.SideMenu {
    width: 318px;
    box-shadow: 8px 0px 5px -5px #ccc;
    position: relative;

    // background-image: url(./assets/images/LoginBanner_AU.jpg);
    // background-repeat: no-repeat;
    // background-position: 7%;
    .SideMenuHeading {
        font-size: 16px;
        color: #000;
        font-family: $fontFamilyBold;
        padding: 14px 32px;
    }

    .SideMenuLinks {
        font-size: 18px;

        .nav-tabs {
            border-bottom: none;
        }

        .SideMenuLinksInner {
            width: 100%;
            border-top: 1px solid #e8e8e8;

            a {
                color: $secondary_color !important;
                text-decoration: none;
                display: flex;
                align-items: center;
                padding: 16px 0px 16px 30px;
                width: 100%;
                font-size: 16px !important;
                line-height: 24px;
                cursor: pointer;

                .SideMenuIcon.svg {
                    width: 18px;
                }

                &:hover {
                    color: $primary_color !important;
                }
            }


            a.active.show {
                color: $primary_color !important;
                //background-color: #f8f8f8;
                font-family: $fontFamilyBold;

                .SideMenuIcon.svg {
                    width: 18px;

                    .st0 {
                        fill: $primary_color !important;
                    }
                }
            }

            span {
                padding-left: 23px;
            }
        }

    }
}

.SideMenu.Right {
    box-shadow: -8px 0px 5px -5px #ccc;
    width: auto;
    max-width: 318px;
    min-width: 318px;
}

.SideMenu.Horizontal_NewMenu {
    width: 100%;
    box-shadow: none;

    .SideMenuLinks {
        .nav-tabs {
            flex-wrap: nowrap;
            border-bottom: 1px solid #dee2e6
        }

        .SideMenuLinksInner {
            width: auto;
            border-top: none;

            a {
                padding: 16px 16px 7px;
                font-family: $fontFamilyBold;
                border-bottom: 3px solid transparent;
                transition: 0.4s all;
            }

            a.active.show,
            a:hover {
                color: $primary_color !important;
                border-bottom: 3px solid $primary_color;
                // .SideMenuIcon.svg {
                //     .st0 {
                //         fill: $primary_color  !important;
                //     }
                // }
            }

            span {
                padding-left: 0px;
            }
        }

        .SideMenuLinksInner:not(:last-child) {
            margin-right: 10px;
        }
    }
}

.FormTemplate {
    padding: 47px 35px 20px;
    // width: calc(100% - 318px);
    min-height: calc(100vh - 102px);
}

.MinLeftFormHeight {
    min-height: 345px;
}

/*Datepicker*/
.clsCommonDatePicker {
    background-image: url(assets/images/Calender.svg) !important;
    background-repeat: no-repeat;
    background-position: 97% center;
    padding-right: 25px !important;
    background-size: 18px;
}

.clsCommonDatePicker.form-control.is-invalid,
.clsCommonDatePicker.was-validated .form-control:invalid {
    background-image: url(assets/images/Calender.svg) !important;
}

.datepicker .table>thead>tr>th,
.datepicker .table>tbody>tr>th,
.datepicker .table>thead>tr>td,
.datepicker .table>tbody>tr>td {
    padding: 6px !important;
}



.datepicker .table {
    margin-bottom: 0px !important;
}

.datepicker td,
.datepicker th {
    text-align: center;
    width: 2em;
    height: 2em;
    border-radius: 3px;
    border: none;
    color: inherit;
    font-size: 14px;
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
    background-color: transparent;
}

.datepicker table tr td.day {
    color: inherit;
}

.datepicker table tr td.day:hover {
    background: $primary_color;
    cursor: pointer;
    color: #fff;
}

.datepicker table tr td.old,
.datepicker table tr td.new {
    color: #9999AE;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
    background: none;
    color: #EEEEEE;
    cursor: default;
}

.datepicker table thead tr {
    border-bottom: 1px solid #ddd;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
    color: #fff;
    background: $primary_color !important;
    font-family: $fontFamilyBold !important;
}

.datepicker table tr td span {
    display: block;
    width: 23%;
    height: 35px;
    line-height: 35px;
    float: left;
    margin: 1%;
    cursor: pointer;
    border-radius: 3px;
    font-size: 14px;
}

.datepicker table tr td span:hover {
    background: $primary_color;
    color: #fff;
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
    color: #9999AE;
    background: none;
    cursor: default;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
    color: #FFF;
    background: #4a7716;
}

.datepicker table tr td span.old {
    color: #9999AE;
}

.datepicker th.switch {
    width: 145px;
}

.datepicker thead tr:first-child th,
.datepicker tfoot tr:first-child th {
    cursor: pointer;
}

.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr:first-child th:hover {
    background: $primary_color;
    color: #fff;
}

.datepicker .datepicker-switch {
    color: $primary_color !important;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
    display: block;
    cursor: pointer;
    width: 16px;
    height: 16px;
}

.datepicker-minutes thead,
.datepicker-hours thead {
    display: none;
}

.datepicker-minutes td,
.datepicker-hours td {
    width: 220px;
}

.TableDataSectionHeading {
    display: flex;
    min-height: 48px;
    border-bottom: 1px solid rgba(68, 68, 68, 0.1019607843);
    width: 100%;

    .nav-tabs {
        border-bottom: 0;
    }

    .DataHeading {
        display: flex;
        font-family: $fontFamilyBold;
        color: #000;
        font-size: 16px;

        .DataHeadingInner {
            display: flex;
            align-items: center;
            margin-right: 28px;
            cursor: pointer;

            a {
                color: inherit;
                text-decoration: none;
                opacity: 0.4;
                transition: all 0.3s;
                display: flex;
                align-items: center;

                &:hover {
                    opacity: 0.6;
                }
            }

            .TableHeadIcon {
                height: 17px;
                width: 17px;
                margin-right: 10px;
            }

        }

        .DataHeadingInner:last-child {
            margin-right: 0;
        }

        .DataHeadingInner a.active {
            opacity: 1;

            &:hover {
                opacity: 1;
            }
        }
    }

    .DataFilter {
        display: flex;
        align-items: center;
    }
}

.MainDataLRPadding {
    padding: 0 20px;
}

.pt-15 {
    padding-top: 15px;
}

.VerticlePagedata {
    flex-direction: column;
}

.CommonTable {
    width: 100%;

    thead {
        color: $Table_Head_Text;
        background-color: $Table_Header_Bg;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        th {
            font-size: 14px;
            font-family: $fontFamilyBold;
            padding: 16px 10px;
            border: 1px solid #f2f2f2;
        }
    }

    tbody {
        tr {
            border: 1px solid #f2f2f2;

            // &:hover {
            //     background-color: #f7f7f7;
            // }

            td {
                font-size: 14px;
                padding: 16px 10px;
                vertical-align: bottom;
                color: black;
                position: relative;
                background-color: white;
                //font-weight: bold; //commented by Rohan
            }
        }

        // tr:last-child {
        //     border-bottom: none;
        // }

        tr.group {
            background-color: #f7f7f7;
            font-family: $fontFamilyBold;
        }

        tr.group.SchemeGroup {
            background-color: #fff !important;
        }
    }

    tfoot {
        tr {
            background-color: #DEDEDE;
            font-family: $fontFamilyBold;

            th {
                font-size: 14px;
                font-family: $fontFamilyBold;
                padding: 16px 10px;
            }
        }
    }

    .BtnCommon {
        padding: 2px 10px;
    }
}

.BtnCommon {
    border: none;
    border-radius: 3px;
    font-size: 12px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
    align-items: center;
    justify-content: center
}

.BtnExport {
    /*min-width: 80px;*/
    border-radius: 50%;
    padding: 4px 9px !important;
}

.BtnBlue {
    background-color: $primary_color;
}

.BtnGreen {
    background-color: #3A7517 !important;
}

.BtnYellow {
    background-color: #F9A63A;
}

.BtnRed {
    background-color: #D70025;
}

.BtnGray {
    background-color: #999;
}

.ExportToIcon {
    width: 12px;
    /*margin-right: 5px;*/
    height: 20px;
    //filter: invert(100%) sepia(0%) saturate(7434%) hue-rotate(30deg) brightness(113%) contrast(116%);
}

.TblMoreMenuIcon {
    cursor: pointer;
    width: 12px;
    display: flex;
    //filter: invert(47%) sepia(21%) saturate(6999%) hue-rotate(202deg) brightness(101%) contrast(92%);
}

.ShowHideMoreMenu {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        margin: 0 10px;
    }
}

.TblMoreMenuOuter,
.SelectedTr {
    background-color: $Table-Header-Bg;
}

.TblMoreMenuOuter.Close {
    display: none;
    transition: all 0.5s;
}

.TblMoreMenuOuter.Open {
    display: flex;
    transition: all 0.5s;
    position: absolute;
    right: 80px;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(87, 87, 87, 0.42);
    padding: 13px;
    background-color: #fff;
    z-index: 1;
}

/*Common Tabs*/
.RightSideTabs {
    margin-left: auto;

    .nav-tabs {
        padding: 0px 15px;
        border-bottom: none;

        li {
            a {
                font-size: 14px;
                padding: 4px 8px;
                font-family: $fontFamilyRegular;

                &:hover {
                    text-decoration: none;
                    color: $secondary_color;
                }
            }

            a.active {
                color: #fff !important;
                background-color: $primary_color;
                border-radius: 2px;
                font-family: $fontFamilyMedium;
            }
        }
    }

    select {
        color: $primary_color;
        font-size: 14px;
        border-color: $primary_color;
        border-radius: 3px;
        cursor: pointer;
    }
}

.GridGapTop {
    margin-top: 20px;
}

.SectionTopHeading {
    font-size: 16px;
    font-family: $fontFamilyBold;
    color: #000;
    border-bottom: 1px solid #eef4ff;
    padding: 12px 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    /*justify-content: space-between;*/
    svg {
        width: 18px;
        margin-right: 6px;
    }

    span {
        display: flex;
        align-items: center;
        color: $secondary_color;
    }

    span.ReportHead {
        //     background-color: $Table-Header-Bg;
        // padding: 2px 7px;
        //border-radius: 3px;
        margin-right: 15px;
    }
}

.SectionHeadNavBar {
    .nav-tabs {
        padding: 12px 15px;
        border-bottom: 1px solid #eef4ff;

        li {
            a {
                font-size: 16px;
                color: $secondary_color;
                margin-right: 15px;
                opacity: 0.5;
                display: flex;
                align-items: center;

                &:hover {
                    text-decoration: none;
                }

                svg {
                    width: 17px;
                    margin-right: 6px;
                }
            }

            a.active {
                font-size: 16px;
                font-family: $fontFamilyMedium;
                opacity: 1;
            }
        }
    }
}

.alignFlexEnd {
    display: flex;
    justify-content: space-between;
}

.TabContentData {
    padding: 10px 15px;
}

.NoBorder {
    border: 0 !important;
}

.ViewMoreLink {
    font-size: 14px;
    margin-left: auto;
    font-family: $fontFamilyMedium;
}

.ResendOTPLink {
    font-size: 12px;
    margin-top: 10px;
}

.HorizontalRdbList {
    display: flex;
    //justify-content: space-between;
    align-items: center;

    label {
        margin: 0 0 0 10px;
    }
}

.AlertModalBody {
    min-height: 85px;
}

.AlertModalHeader {
    color: #353535;
    font-family: $fontFamilyBold;
}

.AlertModalContentCls {
    border-radius: 0 !important;
}

.AlertModalFooter {
    border-top: none !important;
    padding-top: 0 !important;

    button {
        border: none !important;
        background-color: $primary_color !important;
        color: #fff !important;
        font-size: 16px !important;
        padding: 3px 10px 4px !important;
        height: 30px !important;
        min-width: 83px !important;
        border-radius: 3px !important;
        text-align: center !important;
        text-transform: none !important;
        line-height: initial !important;
        margin: 0.25rem !important;
    }
}

progress-bar {
    .progress-outer {
        margin: 10px 0 !important;
        width: 100% !important;
        border-radius: 0 !important;
        padding: 0 !important;
        border: 0 !important;

    }

    .progress-inner {
        min-width: 0 !important;
        min-height: 5px !important;
        height: 5px !important;
        border-radius: 0 !important;
    }
}

.progressName {
    margin-left: 10px;
    font-size: 13px;
}

.progressPer {
    font-size: 11px;
    font-family: $fontFamilyMedium;
}

.progressPerBg {
    background-color: #f1f1f1;
    padding: 2px 5px;
    border-radius: 3px;
}

.progressVal {
    font-size: 13px;
    font-family: $fontFamilyMedium;
    color: #000;
}

.NoRecordFound {
    color: #cecece;
    font-family: $fontFamilyMedium;
    font-size: 12px;
}

.NoRecordFoundAlign {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
}

.NoRecordFoundAlignTable {
    height: 100px;

    td {
        vertical-align: middle !important;
        color: #cecece !important;
        font-family: $fontFamilyMedium !important;
        font-size: 12px !important;
        text-align: center !important;
    }
}

/*Calculators*/
.CalculatorsMainOuter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;

    input {
        font-size: 16px !important;
        color: #000 !important;
        font-family: $fontFamilyMedium !important;
        padding-right: 22px !important;
    }

    .InputBoxOuter span {
        position: absolute;
        top: 7px;
        right: 13px;
        color: #000;
        font-size: 16px;
        font-family: $fontFamilyMedium !important;
    }
}

.CalculatorLabel {
    label {
        margin-bottom: 0;
    }
}

.CalculatorsOuterBox {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.20);
    border-radius: 3px;
    margin-top: 45px;
    max-height: 345px;
    height: fit-content;

    .CalculatedChartOuter {
        border-bottom: 2px solid #eee;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .CalculatorChart {
            height: 160px;
        }

        .CalculatorLegendsOuter {
            padding-top: 20px;

            .CalculatorLegends {
                .LegendColorBox {
                    height: 10px;
                    width: 10px;
                }

                .LegentText {
                    font-size: 12px;
                    padding-left: 7px;
                    color: $body_color_gray;
                }

                .LegendName {
                    display: flex;
                    align-items: center;
                }

                .LegendValue {
                    font-size: 16px;
                    color: #000;
                    font-family: $fontFamilyMedium;
                    margin-bottom: 15px;

                    .fa-inr {
                        font-size: 14px !important;
                    }

                    /*span{
                        padding-left: 7px;
                    }*/
                }
            }
        }
    }

    .CalculatedValueOuter {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 20px;

        .CalculatedBox {
            .CalculatedBoxLabel {
                font-size: 12px;
                color: $body_color_gray;
            }

            .CalculatedBoxValue {
                font-size: 16px;
                color: $body_color;
                font-family: $fontFamilyMedium;

                .fa-inr {
                    font-size: 14px !important;
                }

                span {
                    padding-left: 5px;
                }
            }
        }

        .CalculatedBox:nth-child(1),
        .CalculatedBox:nth-child(2) {
            margin-bottom: 30px;
        }
    }
}

.Isshowcalculatorchart {
    display: none;
}

.Isshowcalculatorchart.show {
    display: block;
}


.ReportChartOuter {
    /*border-bottom: 2px solid #eee;*/
    display: grid;

    .ReportChart {
        height: 200px;
        margin-top: 24px;
    }

    .ReportLegendsOuter {
        padding-top: 20px;

        .ReportLegends {
            .LegendColorBox {
                height: 10px;
                width: 10px;
            }

            .LegentText {
                font-size: 12px;
                padding-left: 7px;
            }

            .LegendName {
                display: flex;
                align-items: center;
            }

            .LegendValue {
                font-size: 16px;
                color: #000;
                font-family: $fontFamilyMedium;
                margin-bottom: 15px;

                .fa-inr {
                    font-size: 14px !important;
                }

                span {
                    padding-left: 7px;
                }
            }
        }
    }


}

.AddHoverEffect.hoveredThis {
    background: #ECECEC !important;
    cursor: pointer;
}

.invalid-feedback {
    font-size: 12px !important;
    margin-top: 4px !important;
}

/* Data Table */
table.dataTable tr.dtrg-group td,
table.dataTable tr.GroupTrBg td {
    font-family: $fontFamilyBold !important;
    background-color: #ECECEC !important;
}

table.dataTable tr.dtrg-group.dtrg-level-0 td {
    color: #000;
    background-color: rgb(235 47 42 / 15%) !important;
    font-family: $fontFamilyBold !important;
}

table.dataTable tr.dtrg-group.dtrg-level-0.FirstLevelTotal td {
    background-color: rgb(235 47 42 / 25%) !important;
}

table.dataTable tr.dtrg-group.dtrg-level-1 td {
    color: #000;
    background-color: rgb(14 117 189 / 15%) !important;
    font-family: $fontFamilyBold !important;
}

table.dataTable tr.dtrg-group.dtrg-level-1.FirstLevelTotal td {
    background-color: rgb(14 117 189 / 25%) !important;
}

table.dataTable tr.dtrg-group.dtrg-level-1 td:first-child {
    padding-left: 2em !important;
}

table.dataTable tr.dtrg-group.dtrg-start {
    cursor: pointer;
}

// table.dataTable thead>tr>th.sorting_asc, table.dataTable thead>tr>th.sorting_desc, table.dataTable thead>tr>th.sorting, table.dataTable thead>tr>td.sorting_asc, table.dataTable thead>tr>td.sorting_desc, table.dataTable thead>tr>td.sorting {
//     /*padding-right: 6px;*/
// }
// table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after{
//     /*bottom: 22px !important;*/
// }
table.dataTable tbody>tr.selected,
table.dataTable tbody>tr>.selected {
    background-color: #fff !important;
}

table.dataTable tbody>tr.selected:hover,
table.dataTable tbody>tr>.selected:hover {
    background: $Table-Header-Bg !important;
}

.MinLeftFormHeightTransact {
    min-height: 320px;
}
.MinLeftFormHeightTransact1{
    min-height: 100px;
}
.actionColumn {
    width: 6% !important;
}

.multiselect-dropdown {
    .dropdown-list {
        box-shadow: none !important;
        border-radius: 0 !important;
        padding-top: 0 !important;

        .no-data h5 {
            font-size: 14px !important;
        }

        ul {
            position: relative !important;
        }
    }

    .dropdown-btn {
        border: none !important;
        border-bottom: 1px solid #eaeaea !important;
        border-radius: 0 !important;
        padding: 6px 0 8px !important;

        .dropdown-down {
            background-image: url(./assets/images/DownArrow.svg);
            background-color: transparent;
            background-size: contain;
            background-repeat: no-repeat;
            border: none !important;
            height: 10px !important;
            width: 10px !important;
            margin: auto !important;
            top: auto !important;
            left: auto !important;
            transition: all 0.4s;
        }

        .dropdown-up {
            @extend .dropdown-down;
            transform: rotate(180deg);
        }

        .selected-item {
            border: 1px solid $primary_color !important;
            margin-right: 4px;
            background: $primary_color !important;
            padding: 0 5px;
            color: #fff;
            border-radius: 2px;
            float: left;
        }
    }
}

.multiselect-item-checkbox input[type=checkbox]:checked+div:before {
    -webkit-animation: .2s ease-in borderscale;
    animation: .2s ease-in borderscale;
    background: $primary_color !important;
}

.multiselect-item-checkbox input[type=checkbox]+div:before {
    box-sizing: content-box;
    content: '';
    color: $primary_color !important;
    position: absolute;
    top: 50%;
    left: 0;
    width: 14px;
    height: 14px;
    margin-top: -9px;
    border: 2px solid $primary_color !important;
    text-align: center;
    transition: .4s;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #fff; //#e9ecef;
    opacity: 1 !important;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #fff !important;
    cursor: not-allowed !important;
}

.fa.fa-trash {
    cursor: pointer;
}

/*CommonTooltip*/
.CommonTooltip {
    background-color: #000 !important;
    color: #fff !important;
}

/*Password Eye*/
.ngx-show-hide-password {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 6;

    button {
        background-color: transparent;
        /*border-bottom: 1px solid #eaeaea;*/
        border-radius: 0;
        padding-right: 5px;
        padding-left: 5px;
    }
}

show-hide-password {
    button {
        fa-icon svg {
            width: 18px !important;

            path {
                fill: #d1d1d1 !important;
            }
        }
    }
}

.input-group-append .btn,
.input-group-prepend .btn {
    position: relative;
    z-index: 4 !important;
}

.close {
    line-height: 0.8 !important;
}

ngb-modal-window {
    .modal-dialog {
        max-width: 500px !important;
        margin: 1.75rem auto;
        display: flex;
        align-items: center;
        min-height: calc(100% - (1.75rem * 2));

        .modal-content {
            border-radius: 0 !important;

            .modal-body {
                min-height: 85px;
            }

            .modal-header {
                color: #353535;
            }

            .modal-footer {
                border-top: none !important;
                padding-top: 0 !important;
                font-size: 16px !important;

                button {
                    border: none;
                    color: #fff;
                    font-size: 16px;
                    padding: 1px 10px 4px;
                    height: 30px;
                    min-width: 80px;
                    border-radius: 3px;
                }
            }
        }
    }
}

.mCSB_inside>.mCSB_container {
    margin-right: 20px !important;
}

.dropdown-list .filter-textbox input {
    padding: 0px !important;
}

/* Go To Top*/
#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: $primary_color;
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;

    img {
        display: block;
        width: 35%;
        margin: 12px auto;
    }
}

/*#return-to-top i{
    color: #fff;
    margin: 0;
    position: relative;
    left: 16px;
    top: 13px;
    font-size: 19px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}*/
#return-to-top:hover {
    background: $primary_color;
}

#return-to-top:hover i {
    color: #fff;
}

/*Export to PDF*/
.page-break {
    page-break-after: always;
    page-break-inside: avoid;
    clear: both;
}

.page-break-before {
    page-break-before: always;
    page-break-inside: avoid;
    clear: both;
}

/*#ClientsTable_filter{
    position: absolute;
    top: -65px;
    left: -75px;
}
#FamilyTable_filter{
    position: absolute;
    top: -65px;
    right: 170px;
}*/

#ClientsTable_wrapper {
    .sm-12 {
        padding: 0 !important;
    }
}

.page-item.active .page-link {
    background-color: $primary_color !important;
    border-color: $primary_color !important;
}

div.dataTables_wrapper div.dataTables_info {
    padding-left: 10px !important;
}

.actionColumn {
    .fa {
        font-size: 14px !important;
    }
}

div.dataTables_wrapper div.dataTables_filter label {
    color: $primary_color !important;
}

div.dataTables_wrapper div.dataTables_filter input {
    border: none !important;
    border-bottom: 1px solid $primary_color !important;
    border-radius: 0 !important;
    color: $primary_color !important;
}

/*Range slider*/
.ReturnSlider {
    width: calc(100% - 21px);
    margin-bottom: 10px;

    p {
        font-size: 14px;
    }
}

.custom-slider .ng5-slider {
    margin: 0
}

.custom-slider .ng5-slider .ng5-slider-pointer {
    cursor: pointer;
    padding-top: 0;
    width: 0;
    height: 0;
    background-color: transparent;
    color: $primary_color;
    z-index: 3;
    border-radius: 16px
}

.custom-slider .ng5-slider .ng5-slider-bubble.ng5-slider-ceil,
.custom-slider .ng5-slider .ng5-slider-bubble.ng5-slider-floor,
.custom-slider .ng5-slider .ng5-slider-bubble.ng5-slider-model-value {
    display: none
}

.custom-slider .ng5-slider .ng5-slider-pointer.ng5-slider-active:after {
    background-color: $primary_color !important;
}

.custom-slider .ng5-slider .ng5-slider-bar {
    // background-color: #dee2e6;
    background-color: #DEDEDE;
    height: 4px !important;
    border-radius: 0 !important
}

.custom-slider .ng5-slider .ng5-slider-pointer:active:after,
.custom-slider .ng5-slider .ng5-slider-pointer:after,
.custom-slider .ng5-slider .ng5-slider-pointer:hover:after {
    -webkit-appearance: none;
    width: 15px;
    margin-top: -3px;
    height: 15px;
    border-radius: 50%;
    background: $primary_color;
    cursor: pointer;
    left: 0
}

.custom-slider .ng5-slider-inner-tooltip {
    display: none;
    height: 0
}

.custom-slider .ng5-slider .ng5-slider-selection {
    background: $primary_color !important
}

.custom-slider .ng5-slider .ng5-slider-tick {
    text-align: center;
    cursor: pointer;
    width: 0 !important;
    height: 0 !important;
    background: #d8e0f3;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 0 !important
}

.custom-slider .ng5-slider .ng5-slider-tick.ng5-slider-selected {
    background: $primary_color !important;
}

.custom-slider .ng5-slider .ng5-slider-bar-wrapper {
    left: 0;
    box-sizing: border-box;
    margin-top: 0;
    padding-top: 0 !important;
    width: 100%;
    height: auto;
    z-index: 1;
    visibility: visible !important
}

.custom-slider .ng5-slider .ng5-slider-tick-legend {
    position: absolute;
    top: 0 !important;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    max-width: 50px;
    white-space: normal
}

.custom-slider .ng5-slider .ng5-slider-pointer:focus {
    outline: 0
}

.CommonInfoIconSmall {
    width: 12px;
    height: 12px;
    cursor: pointer;
}

.fdSlider .ng5-slider .ng5-slider-pointer.ng5-slider-active:after {
    background-color: $primary_color !important
}

.fdSlider .ng5-slider .ng5-slider-pointer {
    cursor: pointer;
    padding-top: 0;
    width: 15px !important;
    height: 15px !important;
    background-color: $primary_color !important;
    color: $primary_color;
    position: relative;
    top: -7px;
    z-index: 3;
    border-radius: 16px
}

.fdSlider .ng5-slider .ng5-slider-pointer:after {
    background-color: #fff !important;
}

.fdSlider .ng5-slider .ng5-slider-pointer.ng5-slider-active:after {
    background-color: transparent !important;
}

// .ExtraLabel
// {
//     margin-bottom: 1.4rem !important;
// }
.fdSlider .ng5-slider .ng5-slider-bubble {
    font-size: 14px !important;
    top: 10px !important;
}

.fdSlider .ng5-slider .ng5-slider-selection {
    background: $primary_color !important;
    //z-index: ;
    visibility: visible !important;
}

.fdSlider .ng5-slider-inner-tooltip {
    display: none !important;
    height: 0 !important;
}

.fdSlider .ng5-slider .ng5-slider-bubble.ng5-slider-model-value {
    display: none !important;
}

.fdSlider .ng5-slider .ng5-slider-bubble.ng5-slider-ceil,
.fdSlider .ng5-slider .ng5-slider-bubble.ng5-slider-floor {
    display: block !important;
    opacity: 1 !important;
}



.btnmultiselect {
    text-align: center;
    /* padding-bottom: 7px; */
    border-top: 1px solid #c7c0c0;
    padding: 6px;

    button {
        width: 150px;
        height: 40px;
        border-radius: 4px;
        background-color: $primary_color;
        color: #fff;
        border: none;
        cursor: pointer;
        font-family: $fontFamilyBold;
        font-size: 16px;
    }

}

//.logo_img{
// width: 130px;
// max-height: 62px;
//}

.AccountHeader {
    .st0 {
        fill: $primary_color !important;
    }
}

.MyAccountMenu a.AncMyAcc {
    display: flex;
    align-items: center;
    justify-content: center;

    .svg {
        width: 18px;
        margin-right: 4px;
    }
}

.NavbarBg {
    box-shadow: 0px 0px 3px rgb(0 0 0 / 10%);
    background-color: $secondary_color;
    min-height: 40px;
    border-color: #4E4E4E;
    border: 0;
}

.NavbarBg {
    background-color: #fff !important;
}

.Main_ABML_Money .NavbarBg {
    background-color: #fff !important;
}

.LogoutImage .st0 {
    fill: $primary_color !important;
}

.HeaderSVGIcon .st0 {
    fill: $secondary_color;
}
.LogoutClr{
    color: #c91429 !important;
}
@mixin color-opacity($color, $opacity: 0.4) {
    color: $color;
    /* The Fallback */
    color: rgba($color, $opacity);
}

.TopFilters {

    .SearchBox {
        position: relative;

        ::-webkit-input-placeholder,
        ::-moz-placeholder,
        :-ms-input-placeholder,
        :-moz-placeholder {
            @include color-opacity($primary_color, 0.4);
        }

        .searchicon {
            position: absolute;
            right: 4px;
            top: 4px;
            width: 18px;

        }

        .SearchIcon .st0 {
            fill: $primary_color;
        }
    }

}

.ThemeMenuIcon .st0 {
    fill: $secondary_color !important;
}

.MyAccountImage .st0 {
    fill: $secondary_color !important;
}

.MyAccountOuter {
    p {
        .MyAccountImg {
            width: 18px;
            height: 18px;
            margin-right: 6px;
        }
    }
}

.CommonSelectBoxTabsOuter {
    padding: 0 15px;


    // .select2-container--default .select2-selection--single .select2-selection__arrow b{
    //     background-image: url(./assets/images/DownArrowWhite.svg);
    // }

    // .CommonSelectBoxTabs{

    //     border: none !important;
    //     border-radius: 3px !important;
    //     font-size: 14px !important;
    //     color: #fff !important;
    //     padding: 8px 0px !important;
    //     height: 38px !important;
    //     margin-bottom: 0px !important;
    //     background-color: $primary_color;
    //     &:focus{
    //         border: none !important;
    //         box-shadow: none !important;
    //     }
    // }

}

.CommonSelectBoxTabsOuter.CommonSelectBoxForm {
    padding: 0 !important;
    margin: 0px 0 40px !important;

    .select2-container--default .select2-selection--single {
        font-size: 18px !important;
        font-family: $fontFamilyBold !important;
        color: $secondary_color !important;
        border-bottom: 1.5px solid $secondary_color !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        background-image: url(./assets/images/DownArrowTabSelect.svg) !important;
    }

    .DownArrowTabSelect .st0 {
        fill: $secondary_color !important;
    }

}

.ReportFilterIcon {
    background-color: $secondary_color;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    .fa.fa-filter {
        color: #fff !important;
    }
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
    background-color: $primary_color !important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    position: relative;
    padding-left: 30px !important;
    cursor: pointer;
}



@mixin ImpText($mb, $mt) {
    font-size: 18px;
    color: $secondary_color;
    font-family: $fontFamilyBold;
    margin-bottom: $mb;
    margin-top: $mt;
}

.ClsTransactionOTPModel {
    .modal-dialog {
        // max-width: calc(100% - (1.75rem * 2)) !important;
        max-width: calc(100%) !important;
        margin: 0 !important;
    }

    .MainBgOtp {
        background-color: $body_bg_color;
        border: none !important;

        .MainInnerFormWithoutFlex {
            margin-top: 20px !important;
        }
    }
}

.OTPOuter {
    background-color: #fff;
    padding: 25px 15px;
    position: relative;

    .close {
        position: absolute;
        right: 20px;
        top: 15px;
    }

    .head {
        @include ImpText(10px, 0);
    }

    .mob {
        font-family: $fontFamilyMedium;
    }

    .leb {
        @include ImpText(45px, 30px);
    }

    .res {
        font-family: $fontFamilyMedium;
        color: $body_color_gray;
        margin-top: 20px;
        cursor: pointer;
        border: none;
        background-color: transparent;
    }

    .res.act {
        color: $primary_color;
    }

    .time {
        margin: 40px 15px;
        font-size: 20px;
        display: inline-block;
        padding: 33px 0 33px 3px;
        width: 100px;
        height: 100px;
        border: 1px solid #eee;
        border-radius: 50%;
        letter-spacing: 2px;
    }

    .OTPBox.otp-input {
        width: 45px;
        height: 45px;
        border-radius: 0;
        // border: 0px solid #c5c5c5;
        // border-bottom: 1px solid #c5c5c5;
        text-align: center;
        font-size: 25px;
    }
}

.LoginOuter {
    background: linear-gradient(#fff, #fff) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.LoginOuter {
    background-image:none;// url(./assets/images/JM-Background2.png);
    height: 90vh;
    //height: calc(100vh - 62px);

    .MainOuter {
        width: 100%;
        max-width: 360px;
        margin: 100px auto 30px;
        // position: absolute;
        // right: 5%;
        // top: 100px;
    }
}

.LoginOuter.IFA_LoginBg {
    background-image: none !important;
    background-color: #fff;
}

.ForgetPassLink {
    font-size: 12px;
    text-align: right;
    cursor: pointer;
    margin-top: 10px;
    color: $primary_color;
}

.Main_AU .QuickTransactAndCalcSingle:hover .QuickTransactAndCalcImg #Layer_2 .cls-1,
.Main_AU .QuickTransactAndCalcSingle:hover .QuickTransactAndCalcImg #Layer_2.rk .cls-3 {
    fill: #f37435 !important
}

.Main_AU .QuickTransactAndCalcSingle:hover .QuickTransactAndCalcImg #Layer_2.rk .cls-1 {
    fill: none !important;
}

#dataTable2_wrapper {
    width: 100% !important;
}

@media (min-width: 768px) {
    table.dataTable>tbody>tr.child {
        ul.dtr-details {
            display: flex;
            flex-wrap: wrap;

            li {
                padding: 0.5em 15px !important;
                border: none;
                border-right: 1.5px solid #dedede;
            }

            li:first-child {
                padding-left: 0 !important;
            }

            li:last-child {
                border: none !important;
            }
        }
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.99px) {}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.99px) {}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.99px) {
    .datepicker.dropdown-menu {
        position: absolute;
        margin-top: 5px !important;
     top:246px ;
        left: 0;
        z-index: 10000000 !important;
        display: none;
        float: left;
        min-width: 160px;
        padding: 5px 5px;
        margin: 3px 0 0;
        list-style: none;
        background-color: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2);
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        -webkit-background-clip: padding-box;
        -moz-background-clip: padding;
        background-clip: padding-box;
    }
    .nps.MainInnerFormWithoutFlex {
        background-color: transparent !important;
        box-shadow: none !important;
        padding: 0 !important;

        .NpsBg {
            background-color: transparent !important;
        }
    }

    .LoginOuter.AU_LoginBg {
        background-image: linear-gradient(#fdd4c0, #d8cee9) !important;
    }

    .TblMoreMenuOuter.Open {
        display: grid;
        grid-gap: 15px;

    }

    .LoginOuter.Mahindra_LoginBg {
        background-image: none !important;
        background-color: #fff !important;
    }

    .LoginOuter.YesSecurities_LoginBg {
        background-image: none !important;
        background-color: #fff !important;
    }

    .LoginOuter.SPA_LoginBg {
        background-image: none !important;
        background-color: #fff !important;
    }

    .LoginOuter.investedu_LoginBg {
        background-image: none !important;
        background-color: #fff !important;
    }

    .LoginOuter.NSDL_LoginBg {
        background-image: none !important;
        background-color: #fff !important;
    }

    .LoginOuter.Alankit_LoginBg {
        background-image: none !important;
        background-color: #fff !important;
    }

    .LoginOuter.Kotak_LoginBg {
        background-image: none !important;
        background-color: #fff !important;
    }


    .Main_AU {

        .MainInnerFormWithoutFlex,
        .MainInnerForm {
            margin-top: 20px !important;
        }
    }

    .MobileImageOuter {
        display: none;
    }

    .OnBoardingFormOuter {
        padding-left: 0%;
        margin-top: 50px !important;

        .FormHeading {
            min-height: auto !important;

            p {
                font-size: 20px;
                margin-bottom: 35px;
            }
        }
    }

    .WhoIAMLink {
        font-size: 12px !important;
    }

    .navbar.fixed-bottom {
        height: 60px !important;
        padding: 0.5rem !important;
        border-top: 1px solid #ddd;

        .navbar-nav {
            flex-direction: row !important;
            justify-content: space-between;

            li a {
                font-size: 12px !important;
            }

            .svg {
                width: 23px;
            }

            .nav-link.active~.svg .HeaderSVGIcon .st0 {
                fill: $primary_color !important;
            }
        }
    }

    .navbar .navbar-nav .MyAccountMenu {
        padding: 3px 5px !important;
        box-shadow: 0px 0px 2px rgb(0 0 0 / 10%);

        a.AncMyAcc {
            padding: 5px;

            .fa {
                padding: 2px 0 0 4px;
            }

            .svg {
                margin: 0 !important;
            }
        }
    }

    .ConTabToDdl.RightSideTabs {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0 0px 8px 0 rgb(0 0 0 / 8%);

        .nav-tabs {
            li {
                width: 100%;

                a {
                    display: block;
                    width: 100%;
                    border-radius: 0 !important;
                    background-color: #fff;
                    padding: 5px 15px !important;
                    font-size: 14px !important;
                }

                a.active {
                    background-color: $body_bg_color !important;
                    color: $secondary_color !important;
                }
            }
        }
    }

    .TabToDdl {
        padding: 0 15px;
        font-size: 14px;
        height: 35px;
        border-radius: 3px;
        background-color: $primary_color;
        background-repeat: no-repeat;
        color: rgb(117, 27, 27);
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin-top: 20px;
    }

    .RecentTransact {
        .RecentTransactNavOuter {
            display: block !important;
            border-bottom: 0 !important;

            .SectionHeadNavBar .nav-tabs li a {
                font-size: 14px !important;

                svg {
                    width: 15px !important;
                }
            }

            .RightSideTabs {
                margin-top: 12px;

                .nav-tabs li a {
                    font-size: 11px;
                }
            }
        }
    }

    .CommonTable thead th {
        font-size: 11.5px !important;
        padding: 16px 6px !important;
    }

    .CommonTable tbody tr td {
        font-size: 11px !important;
        padding: 16px 6px !important;
    }

    .progressName,
    .progressVal {
        font-size: 11px !important;
    }

    .AMCCharts .AUMProgressOuter {
        min-height: 150px !important;
    }

    // .SideMenu{
    //     position: absolute;
    //     z-index: 3;
    //     display: none;
    // }
    .SubmitMarginTop {
        text-align: center;
    }

    .FormTemplate {
        width: 100% !important;
        padding: 30px 15px 20px !important;
    }

    .MainInnerForm {
        display: block;
        margin-bottom: 100px !important;
    }

    .MainInnerFormWithoutFlex {
        margin-bottom: 72px !important;
    }

    .MainBg {
        min-height: auto !important;
    }

    .CommonSubmitButton {
        min-width: 140px;
        height: 40px;
        font-size: 14px;
        padding: 0 15px;
        margin: 0 5px 20px;
    }

    .CommonSecondaryButton {
        min-width: 140px;
        height: 40px;
        font-size: 14px;
        padding: 0 15px;
        margin: 0 5px 20px;
    }

    .tab-pane h6 {
        font-size: 14px !important;
    }

    hr {
        margin-top: 8px !important;
    }

    div.dataTables_wrapper div.dataTables_filter {
        text-align: right !important;
    }

    .ReportChartOuter .ReportLegendsOuter {
        margin-bottom: 20px;
    }

    .CalculatorsPageOuter {
        .CommonSelectBoxTabsOuter.CommonSelectBoxForm {
            padding: 30px 15px 0 !important;
        }
    }

    .CalculatorsOuterBox {
        max-height: max-content !important;
        margin-bottom: 20px;

        .CalculatedValueOuter {
            grid-template-columns: 1fr;

            .CalculatedBox {
                margin-bottom: 30px;
            }

            .CalculatedBox:last-child {
                margin-bottom: 0px !important;
            }
        }
    }

    .otp-input {
        width: 40px !important;
        font-size: 24px !important;
        height: 40px !important;
    }

    #return-to-top {
        display: none !important;
        bottom: 0px !important;
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.99px) {
    .CommonSubmitButton {
        min-width: 110px;
        height: 35px;
        font-size: 12px;
        padding: 0 10px;
    }

    .CommonSecondaryButton {
        min-width: 110px;
        height: 35px;
        font-size: 12px;
        padding: 0 10px;
    }

    .OnBoardingFormOuter {
        .FormHeading {
            p {
                font-size: 18px;
            }
        }
    }

}

.dashboardmainimg .st0 {
    fill: $secondary_color;
}

.SipCountOuter {
    .SipCount {
        .SipInfoOuter {
            svg {
                width: 28px;
                height: 28px;
            }
        }
    }
}

@media screen and (max-width: 420px) {
    .otp-input {
        width: 30px !important;
        font-size: 18px !important;
        height: 30px !important;
    }
}

.div-center {

    display: flex;
    justify-content: center;
}

.modalhw {
    max-width: 650px !important;
}

.fixed-bottom.navbar .navbar-nav .nav-item.active .svg {
    fill: $primary_color !important;
}

// .fixed-bottom.navbar .navbar-nav .nav-item.active a.nav-link {
//     color: $primary_color  !important;
// }

.fixed-bottom.navbar .navbar-nav .nav-item:hover .svg {
    fill: $primary_color !important;
}

.fixed-bottom.navbar .navbar-nav .nav-item:hover a {
    color: $primary_color !important;
}

.NoBoxShadow {
    box-shadow: none !important;
}

.slick-list {
    padding: 0px !important;
}

.slick-dotted.slick-slider {
    margin-bottom: 13px;
}

.slick-dots {
    bottom: -28px !important;

    li {
        margin: 0 2px;

        button:before {
            font-size: 8px;
        }
    }
}

// .slick-arrow{
//     display: none !important;
// }
.dataTables_paginate .page-link {
    color: $secondary_color;
}

.pagination{
    margin-right: 3rem !important;
}

.FontBold {
    font-family: $fontFamilyBold;
}

.ExportToIconImg {
    width: 25px !important;
    height: 25px !important;
}

/*IPO*/
.SectionTopHeading_IPO {
    padding: 30px 0;
    border-bottom: 2px solid #f0f1ec;

    .MainHeading {
        font-size: 24px;
        color: #1a1a1a !important;
        line-height: 40px;
    }

    .SubHeading {
        font-size: 14px;
        color: #999 !important;
    }
}

.CommonSubmitButton_IPO,
.CommonSubmitButton_IPO:active,
.CommonSubmitButton_IPO:focus {
    border-radius: 4px !important;
    text-align: center;
    user-select: none;
    transition: all 1s ease-out;
    cursor: pointer;
    display: inline-block;
    min-width: 130px !important;
    padding: .6em 1.4em;
    font-size: 1.1em;
    text-decoration: none !important;
    margin: 0 5px;
    text-transform: uppercase;
    background-color: $primary_color;
    color: #fff !important;
    border: 2px solid $primary_color !important;
    font-family: $fontFamilyRegular;
    letter-spacing: 0.7px;
    box-shadow: 0 0 2px #8d8d8d;
}

.CommonSubmitButton_IPO:hover {
    background-color: #fff !important;
    color: $primary_color !important;
    border: 2px solid $primary_color !important;
}

.badge {
    display: inline-block;
    border-radius: 10px;
    font-size: 12px;
    color: #fff;
    margin-left: 5px;
    line-height: 1;
    white-space: nowrap;
    text-align: center;
}

.IPO_Section {
    .MainInnerFormWithoutFlexOnlyOuter {
        box-shadow: none !important;
        margin: 80px auto 20px !important; //45
        background-color: transparent;
    }

    .SectionInnerData {
        padding: 0 30px;
    }

    .SectionInnerDataAll {
        padding: 30px 30px;
    }

    .tab-content {
        padding: 0 0 30px !important;
    }

    .IpoNavTabs.nav-tabs:not(.IpoMobTabs) {
        justify-content: center;
        padding-top: 25px;
        border-bottom: 1px solid #c8c8c8;

        li {
            display: inline-block;
            float: none;
            padding: 0 20px;
            margin-bottom: -1px;

            a {
                text-transform: uppercase;
                color: #8D8D8D;
                font-family: $fontFamilyMedium;
                border: 0;
                padding: 0 0 6px;
                line-height: 12px;
                display: inline-block;
                border-bottom: 3px solid transparent;
                margin-right: -2px;

                &:hover,
                &:focus {
                    color: $primary_color;
                    border-bottom: 3px solid $primary_color;
                    text-decoration: none;
                }
            }

            a.active {
                color: $primary_color;
                border-bottom: 3px solid $primary_color;
            }

            .badge {
                vertical-align: super;
                min-width: 17px;
                padding: 3px 6px;
                background-color: $primary_color;
            }
        }
    }

    .CommonTable {
        thead th {
            padding: 16px 15px;
        }

        tbody {
            tr:hover {
                background-color: transparent;
            }

            td {
                color: #666;
                font-family: $fontFamilyMedium;
                font-size: 14px;
                padding: 15px !important;
            }
        }
    }

    .CommonSectionPadding {
        padding: 20px;
    }
}

.IPOTabSectionHead {
    background-color: $Table_Header_Bg;
    font-family: $fontFamilyMedium;
    padding: 10px 15px;
}

.IPOPopup {
    .IPOPopupHeader {
        font-size: 18px;
        font-family: $fontFamilyMedium;
        margin: 8px 0 40px;
    }

    .IPOPopupContent {
        margin: 20px 0 15px;
    }
}

.SideMenuMobile .SideMenuLinksInner {
    a .SideMenuIcon.svg {
        width: 20px;
    }

    a.active.show {
        color: $primary_color !important;
        font-family: $fontFamilyBold;

        .SideMenuIcon.svg .st0 {
            fill: $primary_color !important;
        }
    }
}

.slick-slider .slick-track {
    min-width: 5000px !important;
}

.InputBoxOuter .input-group {
    display: flex !important;

    .input-group-prepend {
        margin-right: 0px;
    }

    label {
        color: #9b9b9b;
        position: absolute;
        font-size: 12px;
        top: -14px !important;
    }

    .input-group-text {
        border: 0;
        background-color: transparent;
        border-bottom: 1px solid #eaeaea;
        border-radius: 0;
        padding: 0;
        padding-right: 5px;
        color: $body_color;
        font-size: 14px !important;
    }
}

.nav-menus-wrapper {
    .nav-menu>li {
        display: inline-block;
        //float: left;
        text-align: left;
        list-style-type: none;
    }

    .nav-menu>li>a {
        color: #1c3c72;
        text-transform: capitalize;
        font-weight: 400;
        height: 90px;
        padding: 37px 25px;
        display: inline-block;
        text-decoration: none;
        font-size: 14px;
        transition: color 0.3s, background 0.3s;

        &:hover {
            color: #ed2128;
        }
    }

    .nav-menu.align-to-right {
        float: right;
    }

    .megamenu-panel {
        width: 100%;
        padding: 15px;
        display: none;
        position: absolute;
        font-size: 14px;
        z-index: 98;
        text-align: left;
        color: inherit;
        background-color: #fdfdfd;
        box-shadow: 0 10px 20px rgb(0 0 0 / 8%);
        border-radius: 0 0 10px 10px;
    }

    li.acc-log a {
        border: 1px solid #1c3c72;
        border-radius: 5px;
        background-image: url(./assets/images/Kotak/lock.svg);
        background-repeat: no-repeat;
        background-position: top 9px left 5px;
        background-size: 21px;
    }

    li.opn-acc a {
        background-color: #ed2128;
        color: #fff;
        border-radius: 5px;
        position: relative;
        background-image: url(./assets/images/Kotak/pie.svg);
        background-repeat: no-repeat;
        background-position: top 12px left 9px;
        background-size: 15px;

        &:hover {
            color: #fff !important;
        }
    }

    li.bnt-lnk a {
        padding: 10px 12px 10px 30px;
        height: 40px;
        text-align: right;
        margin: 25px 5px;
    }
}

.hwrp {
    position: static !important;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    // box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
    box-shadow: none !important;
    padding: 0px 0px;
    z-index: 1;
    animation: all 1s ease-in-out;
}

.hwrp.fixed {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}

/******************Mahindra login Page testimonial designing layout ***********************/
.testimonial .owl-nav {
    position: absolute !important;
    height: 40px !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 42% !important;
}

.testimonial .owl-nav .owl-prev,
.testimonial .owl-nav .owl-next {
    width: 40px !important;
    height: 40px !important;
    margin: 0 10px !important;
    position: absolute !important;
}

.testimonial .owl-nav .owl-prev {
    left: 25px;
}

.testimonial .owl-nav .owl-next {
    right: 25px;
}

.testimonial .owl-nav .owl-prev,
.testimonial .owl-nav .owl-prev:hover {
    background-image: url(assets/images/left.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 100% !important;
}

.testimonial .owl-nav .owl-next,
.testimonial .owl-nav .owl-next:hover {
    background-image: url(assets/images/right.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 100% !important;
    background-color: transparent !important;
}

.testimonial .owl-prev.disabled,
.testimonial .owl-next.disabled {
    opacity: 0 !important;
}

.banner-right .owl-carousel .owl-item img,
.login-section {
    height: calc(100vh - 41px) !important;
}

.banner-right .owl-carousel .owl-item img {
    object-fit: cover !important;
}

@media only screen and (max-width: 768px) {

    .banner-right .owl-carousel .owl-item img,
    .login-section {
        height: calc(100vh - 40vh) !important;
    }

    .fotgot-pass {
        position: relative;
    }

    .caption-second {
        padding: 60px 60px 30px 80px;
    }

    .caption-second .caption-content {
        min-height: 190px;
    }

    .testimonial .owl-nav .owl-next,
    .testimonial .owl-nav .owl-next:hover,
    .testimonial .owl-nav .owl-prev,
    .testimonial .owl-nav .owl-prev:hover {
        display: none !important;
    }
}

@media only screen and (min-width: 1024px) {
    .testimonial .owl-dot {
        display: none !important;
    }

}

@media only screen and (max-width: 767.99px) {
   
    .CommonSubmitButton_IPO.FullWidthMob,
    .CommonSubmitButton_IPO.FullWidthMob:active,
    .CommonSubmitButton_IPO.FullWidthMob:focus {
        min-width: auto !important;
        margin: 7px 0 !important;
        width: 100% !important;
    }

    .CommonSubmitButton_IPO,
    .CommonSubmitButton_IPO:active,
    .CommonSubmitButton_IPO:focus {
        min-width: auto !important;
        padding: 0.5em 0.9em;
        margin: 5px;
    }
}

::-webkit-scrollbar {
    width: 4px;
    height: 6px;
    background-color: #cdcece;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #6c7174;
}

.CommonSubmitButton.InlineForm {
    width: fit-content !important;
    min-width: fit-content !important;
    height: 38px !important;
}

.CommonSecondaryButton.InlineForm {
    width: fit-content !important;
    min-width: fit-content !important;
    height: 38px !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none !important;
}

.button {
    background-color: #F97440;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

.TblActionIcon {
    width: 20px;
}


.multiselect-dropdown .dropdown-btn .selected-item a {
    display: none !important;
}

.img-btn {
    background: none;
    border: none;
}

.calcDesktop {
    width: 48%;
    display: inline-block;
}

.calcMobile {
    width: 76%;
    height: 41px;
    padding: 6px 12px !important;
    text-align: center;
    margin-left: 20px;
    display: inline;
    font-size: 14px;
    margin-top: 10px;
    align-items: center;
}


.nav-menus-wrapper-close-button {
    width: 30px;
    height: 40px;
    margin: 10px 7px;
    display: none;
    float: right;
    color: #343a40;
    font-size: 26px;
    cursor: pointer;
}

.navigation-portrait .nav-menus-wrapper-close-button {
    display: block;
}

.nav-overlay-panel {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: none;
    z-index: 19999;
}

.submenu-indicator {
    margin-left: 6px;
    margin-top: 4px;
    float: right;
    transition: all 0.2s;
}

.navigation-portrait .submenu-indicator {
    width: 54px;
    height: 44px;
    margin-top: 0;
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    z-index: 20000;
}

.submenu-indicator-chevron {
    height: 6px;
    width: 6px;
    display: block;
    border-style: solid;
    border-width: 0 1px 1px 0;
    border-color: transparent #70798b #70798b transparent;
    transform: rotate(45deg);
    transition: border 0.2s;
}

.navigation-portrait .submenu-indicator-chevron {
    position: absolute;
    top: 18px;
    left: 24px;
}

.nav-menu>.active>a .submenu-indicator-chevron,
.nav-menu>.focus>a .submenu-indicator-chevron,
.nav-menu>li:hover>a .submenu-indicator-chevron {
    border-color: transparent #967adc #967adc transparent;
}

.navigation-portrait .submenu-indicator.submenu-indicator-up {
    transform: rotate(-180deg);
}

.nav-dropdown .submenu-indicator {
    right: 15px;
    top: 10px;
    position: absolute;
}

.navigation-portrait .nav-dropdown .submenu-indicator {
    right: 0;
    top: 0;
}

.nav-dropdown .submenu-indicator .submenu-indicator-chevron {
    transform: rotate(-45deg);
}

.navigation-portrait .nav-dropdown .submenu-indicator .submenu-indicator-chevron {
    transform: rotate(45deg);
}

.nav-dropdown>.focus>a .submenu-indicator-chevron,
.nav-dropdown>li:hover>a .submenu-indicator-chevron {
    border-color: transparent #ff6b00 #ff6b00 transparent;
}

.navigation-landscape .nav-dropdown.nav-dropdown-left .submenu-indicator {
    left: 10px;
}

.navigation-landscape .nav-dropdown.nav-dropdown-left .submenu-indicator .submenu-indicator-chevron {
    transform: rotate(135deg);
}

.nav-dropdown-horizontal .submenu-indicator {
    height: 18px;
    top: 11px;
    transform: rotate(90deg);
}

.navigation-portrait .nav-dropdown-horizontal .submenu-indicator {
    height: 42px;
    top: 0;
    -webkit-transform: rotate(0);
    transform: rotate(0);
}

.navigation-portrait .nav-dropdown-horizontal .submenu-indicator.submenu-indicator-up {
    transform: rotate(-180deg);
}

.tooltip {
    z-index: 1 !important;
}

/*NPS*/
.NpsBg {
    background-color: #f5f7f9 !important;
    max-width: 692px;
    width: 100%;
    margin: 0 auto;
}

.nps.MainInnerFormWithoutFlex {
    background-color: #f5f7f9;
    padding-top: 48px;
    padding-bottom: 48px;
}

.FontBold {
    font-family: $fontFamilyBold;
}

//ipo

//Header
@media (max-width: 767.99px) {
    .navbar .navbar-nav .MyAccountMenu {
        width: 38px !important;
    }
}

.MainInnerFormCPOnboarding {
    background-color: #fff;
    margin: 20px 20px 20px;
    display: flex;
    @extend .BoxShadow;
}

.NAVMain {
    .highcharts-button {
        text {
            fill: #fff !important;
            color: #fff !important;
            font-weight: normal;
            font-family: $fontFamilyBold;
        }

        .highcharts-button-box {
            fill: $primary_color !important;
        }
    }

    .highcharts-button.highcharts-button-disabled {
        opacity: 0.5;
        cursor: not-allowed;

        text {
            cursor: not-allowed;
        }
    }
}

/*NPS*/
.NpsBg {
    background-color: #f5f7f9 !important;
    max-width: 692px;
    width: 100%;
    margin: 0 auto;
}

.nps.MainInnerFormWithoutFlex {
    background-color: #f5f7f9;
    padding-top: 48px;
    padding-bottom: 48px;
}

.FontBold {
    font-family: $fontFamilyBold;
}

// html,
// body {
//     height: 100%;
// }

// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.SearchPosi .dataTables_filter {
    float: left !important;
    position: absolute !important;
    top: 55px !important;
    text-align: left !important;
}

.ModalNPS .wrapper .otp-input:not(:last-child) {
    margin-right: 4px !important;
}

.ModalNPS .OTPOuter .OTPBox.otp-input {
    border: none !important;
    border-bottom: 1px solid #d3d3d3 !important;
}

.ModalNPS .OTPOuter .res {
    margin-top: 50px !important;
}

table.CommonTable.dataTable.nowrap td {
    white-space: inherit !important;
}

.modal_otp.modal-lg {
    max-width: 100% !important;
    max-height: 100vh !important;
    margin: 0 !important;
    border-radius: 0 !important;
}

.tooltiplink {
    position: relative;
}

.tooltiplink[data-target]:hover::after {
    content: attr(data-target);
    background-color: #000000;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 14px;
    display: block;

    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;
}

@media (max-width: 800px) {
    .tooltiplink {
        position: relative;
    }

    .tooltiplink[data-target]:hover::after {
        content: attr(data-target);
        background-color: #000000;
        color: #fff;
        padding: 8px;
        border-radius: 4px;
        font-size: 12px;
        line-height: 14px;
        display: block;

        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        white-space: inherit !important;
        z-index: 1;
    }
}

.slick-prev,
.slick-next {
    top: auto;
    bottom: -35px;
    transform: none;
    z-index: 1;
}

.slick-prev {
    left: 7px;
}

.slick-next {
    right: 5px;
}

.slick-prev:before,
.slick-next:before {
    color: #000;
}

/*Alankit*/
.Custom_TextBox {
    height: 50px !important;
    border-radius: 10px !important;
    box-shadow: 0px 5px 5px rgba(165, 165, 165, 0.19) !important;
    background-color: #ffffff !important;
    border: 1px solid #e5ebf0 !important;
    font-size: 12px !important;
    color: #727272 !important;
    margin-top: 4px !important;
    font-family: "Poppins-Regular" !important;
    position: relative !important;
    padding-left: 40px !important;
    font-weight: 600;
}

.Custom_TextBox:focus {
    border: 1px solid #0275f3 !important;
}

.Custom_Login {
    width: 100% !important;
    height: 55px !important;
    border-radius: 24px !important;
    box-shadow: 0px 13px 20px rgba(55, 55, 55, 0.35) !important;
    background-color: #0275f3 !important;
    font-size: 25px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Poppins-Regular";
}

.Main_Alankit .dashboardmainimg .st0 {
    fill: $primary_color !important;
}

.Main_Alankit .HeaderSVGIcon .st0 {
    fill: $primary_color !important;
}

.CheckAgree .Cbx_container {
    input {
        display: none;
    }

    label {
        margin-left: 25px !important;
    }
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background: #fff;
    background-image: url("./assets/images/Unchecked.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer !important;
}

.RadioBtn .checkmark {
    background-image: url("./assets/images/RadioBtn_Unchecked.svg") !important;
}

/* When the checkbox is checked, add a blue background */
.Cbx_container input:checked~.checkmark {
    // background-color: #fff;
    // background-image: url("./assets/images/CheckBox.svg");
    // background-repeat: no-repeat;
    // background-position: center;
    background-color: $primary_color;
    background-image: none;
}

.Cbx_container.RadioBtn input:checked~.checkmark {
    //background-image: url("./assets/images/RadioBtn_Checked.svg") !important;
    background-color: $primary_color;
    background-image: none !important;
    border-radius: 50%;
}

// .Cbx_container input~label {
//     // margin-left: 10px !important;
// }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.Cbx_container input:checked~.checkmark:after {
    display: block;
    left: 5.5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.Cbx_container.RadioBtn input:checked~.checkmark:after {
    display: block;
    left: 4px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 2px 4px;
    transform: none;
    border-radius: 50%;
}



.modal-content {
    border-radius: 0px !important;
}

.custom-radios {

    input[type="radio"] {
        display: none;

        +label {
            // height: 38px;
            display: -webkit-box;
            display: inline-block;
            // padding: 8px 30px;
            padding: 4px;
            width: 110px;
            border-radius: 4px;
            margin: 0px 15px;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            cursor: pointer;
            text-align: center;
            transition: all 0.2s ease;
            border: 2px Solid #cecece;
            line-height: 15px;
            font-size: 11px;
            // border-radius: 5px;
        }

        &:checked+label {
            background-color: $primary_color;
            color: #fff;
            border-color: var(--primary-color);
        }
    }

    .labelBtn {
        background-color: #fff;
        color: #777;
        font-family: $fontFamilyBold;
        font-size: 14px;
        width: 115px;
    }
}

.vl {
    border-left: 2px solid #d3d3d3;
    height: 50px;
    position: relative;
    bottom: 5px;
}

// JM Financial style
.SectionTopHeading_n {
    font-size: 18px;
    font-family: $fontFamilyMedium;
    color: #000;
    margin-bottom: 5px;
}

.DotTabs {
    margin-left: auto;

    .nav-tabs {
        padding: 0px 15px;
        border-bottom: none;

        li {
            margin-bottom: 15px;

            a {
                font-size: 17px !important;
                // padding: 0 24px 0 0;
                font-family: $fontFamilyRegular;
                color: #444444;

                &:hover {
                    text-decoration: none;
                    color: $secondary_color;
                }
            }

            a.active {
                color: $secondary_color !important;
                background-color: transparent;
                border-radius: 2px;
                font-family: $fontFamilyBold;
                // border-bottom: 1px solid;
            }
        }
    }

    select {
        color: $primary_color;
        font-size: 14px;
        border-color: $primary_color;
        border-radius: 3px;
        cursor: pointer;
    }
}

.SchemeHeader {
    color: #000;
    font-size: 16px !important;
    font-family: $fontFamilyMedium !important;
}

.SubHeader {
    color: #444444;
    font-size: 16px;
    font-family: $fontFamilyRegular !important;
    margin-top: 4px;
}

.KnowMore {
    font-size: 12px;
    font-family: $fontFamilyMedium !important;
    color: #444444;
}

.SchemeDetails {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-gap: 20px;
    padding: 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #4444441a;
}

.InnerHead {
    font-size: 14px;
    font-family: $fontFamilyRegular !important;
    color: #444444;
}

.Value {
    font-size: 16px;
    font-family: $fontFamilyMedium !important;
    color: #000000;
}

.Button_n {
    min-width: 105px;
    height: 36px;
    color: #fff;
    cursor: pointer;
    font-family: $fontFamilyMedium;
    font-size: 14px;
    margin: 0;
}

.CardBottom {
    margin-bottom: 5px;
}

.NoteOuter {
    line-height: 18px;
}

.Note_n {
    font-size: 14px;
    font-style: italic;
    color: #454545a6;
}

.View {
    font-size: 14px;
    color: $secondary_color;
    text-align: end !important;
    margin: 16px 0;
    cursor: pointer;
}

.M-Top {
    margin-top: 15px;
}

.UnderLineTabsContent {
    background-color: #fff;
    border-radius: 7px;
    // padding: 16px 0 0; //by rohan
}

.DotTabsContent {
    background-color: #fff;
    border-radius: 7px;
    padding: 20px 20px;
}

.UnderLineTabs .nav-tabs {
    border-bottom: 1px solid #4444441a !important;
}

.UnderLineTabs .nav-tabs li a.active {
    color: $primary_color;
    border-bottom: 2px solid $primary_color;
    font-family: $fontFamilyMedium !important;
}

.UnderLineTabs .nav-tabs li a {
    font-family: $fontFamilyRegular !important;
    font-size: 14px;
    padding: 16px 0px 12px;
    margin: 0px 0px 0 20px;
    color: #444444;
    cursor: pointer;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.DotTabs .nav-tabs li {
    position: relative;
    z-index: 1;
    margin-right: 24px;
}

.DotTabs .nav-tabs li:last-child {
    margin-right: 0;
}

.DotTabs .nav-tabs li a.active:before {
    content: "";
    position: absolute;
    left: 43%;
    top: 25px;
    height: 15px;
    width: 4px;
    // border-bottom: 5px solid $secondary_color;
    background-image: url(./assets/images/BlueDot.svg);
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
}

.UnderLineTabs .nav-tabs .nav-link:hover {
    border-color: none !important;
}

.UnderLineTabs .nav-tabs .nav-link.active {
    border-bottom: 2px solid $primary_color;
}

.AUMClr {
    color: $secondary_color;
    font-family: $fontFamilyMedium;
    font-size: 16px;
}

.AUMText_n {
    font-size: 16px;
    color: #444444;
    font-family: $fontFamilyMedium;
}

.SecondTitleCommon {
    font-size: 14px;
    color: #454545a6;
    font-family: $fontFamilyRegular;
}

.BorderR {
    border-radius: 7px;
}

.FontXl {
    font-size: 25px !important;
}

.ClrBlue_n {
    color: $secondary_color !important;
}

.ClrRed_n {
    color: $primary_color !important;
}

.ClrGreen_n {
    color: #2DA76C;
}

.InnerSection {
    padding: 20px;
    border-radius: 7px;
}

.GridTopSpace,
.OuterSection {
    margin-top: 46px;
}

.MinTopMargin {
    margin-top: 150px;
}

// .Main_ABML_Money {
    .MainBg {
        background-color: transparent !important;
        min-height: auto !important;
    }

    .select2-container--default .select2-selection--single {
        height: 42px !important;
        border: 2px solid #eaeaea;
        border-radius: 4px !important;
        // width: 250px !important;
    }

    .has-float-label label {
        color: #00000066 !important;
        font-size: 11px !important;
        background: #fff !important;
        margin-left: 10px !important;
        padding: 0px 3px !important;
        top: -8px !important;
        font-size: 11px !important;
        z-index: 4;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        color: #424242 !important;
        line-height: 38px;
        padding-left: 12px !important;
        font-size: 14px !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        top: 16px !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__placeholder {
        color: #00000066 !important;
    }

    .CommonTextBox,
    .CommonSelectBox_New {
        border: 2px solid #eaeaea !important;
        border-radius: 4px !important;
        font-size: 14px !important;
        color: $body_color !important;
        //padding: 8px 0px 0px 8px !important; //by rohan
        padding: 0px 0px 0px 8px !important;
        height: 42px !important;
        margin-bottom: 0px !important;
        // width: 250px !important;
    }

    .CommonSubmitButton.sm,
    .CommonSecondaryButton.sm {
        width: auto !important;
        height: 33px !important;
        font-size: 14px !important;
        min-width: auto;
        padding: 0 12px;
    }

    .Button_nXL {
        min-width: 158px !important;
        height: 40px !important;
    }

    .CheckAgree span {
        font-size: 14px !important;
    }

    .InputBoxOuter {
        margin-bottom: 20px !important;
        //padding-right: 5px; //by rohan
        // margin-left: 10px;
        // margin-right: 10px;
    }

    .RightSpace {
        padding-right: 15px !important;
    }

    .has-float-label input:placeholder-shown:not(:focus)+*,
    .has-float-label select:placeholder-shown:not(:focus)+*,
    .has-float-label textarea:placeholder-shown:not(:focus)+* {
        opacity: 1 !important;
    }

    .navbar {
        .navbar-nav {
            margin-left: 230px; //!important

            .nav-item {
                margin-top: 8px !important;
            }
        }

        .navbar-nav.RightSideMenu {
            margin-left: auto !important;
        }
    }

    .MainInnerForm,
    .MainInnerFormWithoutFlex {
        margin: 20px;
        border-radius: 7px;
        box-shadow: none !important;
    }

    .NewHeadOuter {
        margin: 20px;
    }

    .NewHeadOuterInsideBox {
        padding: 15px 20px 10px;
        border-bottom: 1px solid #e7e7e7;
    }

    .FormTemplate {
        padding: 20px;
        min-height: auto;
    }

    .CommonTable tbody tr td {
        font-family: $fontFamilyRegular !important;
        // font-size: 12px !important;
    }



    .card {
        border-radius: 7px;
    }

    .hint {
        color: #00000066 !important;
        font-size: 12px;
    }
// }

.Head {
    color: #424242;
    font-size: 14px;
    font-family: $fontFamilyMedium;
}

.SubHead {
    font-size: 12px;
    color: #00000066;
}

.SummaryInner {
    margin-bottom: 16px;
}

@media (max-width: 767px) {
    // .Main_ABML_Money {
        .DotTabs .nav-tabs li a {
            font-size: 14px !important;
            // padding: 0 10px 0 0 !important;
        }

        .SectionTopHeading_n {
            font-size: 16px !important;
        }

        .SchemeHeader {
            color: #000;
            font-size: 14px !important;
        }

        .UnderLineTabs .nav-tabs li a {
            font-size: 13px !important;
        }

        .AUMText_n,
        .AUMClr {
            font-size: 12px !important;
        }

        .DotTabsContent {
            padding: 16px !important;
        }

        .UnderLineTabs .nav-tabs li a {
            margin: 0 0 0 16px !important;
            padding: 10px 0px 10px !important;
        }

        .SecondTitleCommon {
            font-size: 11px !important;
            color: rgb(69 69 69 / 50%) !important;
        }

        .TopMost-1 {
            font-size: 12px !important;
        }

        .TopMostOuter {
            margin-bottom: 16px !important;
            padding-bottom: 16px !important;
        }

        .Value {
            font-size: 12px !important;
            color: #444444;
        }

        .InnerSection {
            padding: 16px !important;
        }

        .AssisInner {
            margin-top: 10px !important;
        }

        .DotTabs .nav-tabs li {
            margin-right: 16px !important;
        }

        .DotTabs .nav-tabs li a.active:before {
            width: 4px !important;
        }

        .AlignIcn .fa-circle {
            font-size: 4px !important;
        }

        .DotTabs .nav-tabs li a.active:before {
            top: 23px !important;
        }
        .Filltabs .nav-tabs .nav-item .nav-link {
            padding: 2px 8px;
            font-size: 12px;
        }
    // }
}

// .Main_ABML_Money {
    .MainBg {
        padding-left: 230px;
        transition: all 0.5s;
    }

    .MainBg.collapsed_Menu {
        padding-left: 68px;
        transition: all 0.5s;
    }

    .navbar {
        height: 62px;
        border-bottom: 1px solid #f5f4f4;
        padding: 0 16px;

        .navbar-brand {
            .logo {
                // width: 138px;
                // height: 32px;
                height: 44px;
            }

            .Companylogo_Web {
                // width: 138px;
                height: 62px;
            }
        }

        .hamrburg {
            height: 36px;
            width: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fafafa;
            border-radius: 50%;
            margin-right: 16px;
        }
    }

    .Sidemenu {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 230px;
        background-color: #fff;
        height: 100%;
        overflow: auto;
        position: fixed;
        transition: all 0.5s;
        padding-bottom: 70px;
        li {
            cursor: pointer;

            a {
                display: block;
                color: #444;
                padding: 11px 24px;
                text-decoration: none;

                img,
                svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 20px;
                }

                img.UserImg {
                    margin-right: 10px !important;
                }
            }
        }

        li.active a {
            color: $secondary_color;
            font-family: $fontFamilyMedium;

            svg path {
                fill: $secondary_color;
            }
        }

        // li a.active {
        //   background-color: #D39B43;
        //   border-radius: 4px;
        //   // padding: 12px;
        //   // color: white;
        // }
        // li a:hover:not(.active) {
        //   background-color: #D39B43;
        //   border-radius: 4px;
        //   // color: white;
        // }
    }

    .Sidemenu.collapsed {
        width: 68px;
        li {
            a {
                //img,
                svg {
                    margin-right: 0px;
                }
            }
        }
    }

    .MarginTopAsHeader {
        margin-top: 62px;
    }

    .RouterContentOuter {
        margin-left: 230px;
        transition: all 0.5s;
        padding: 18px;
    }

    .RouterContentOuter.collapsed {
        margin-left: 68px;
    }

    .UserImg {
        width: 36px !important;
        height: 36px !important;
        border-radius: 50% !important;
    }

    .UserName_Mob {
        font-size: 14px !important;
        font-family: $fontFamilyMedium !important;
        // width: 50px;
    }

    .Hello {
        font-size: 14px !important;
        font-family: $fontFamilyRegular !important;
    }

    .Update_n {
        font-size: 14px;
        font-family: $fontFamilyRegular;
        color: #EB7C2A;
        cursor: pointer;
    }

    .Status_n {
        font-size: 14px;
        font-family: $fontFamilyRegular;
        color: #000000;
    }

    .Home_n {
        color: $secondary_color;
    }

    .CollapseUser {
        border-bottom: 1px solid #f1f1f1;
        padding-bottom: 20px;

    }

    .UserImgDefault {
        border-radius: 50% !important;
        width: 36px !important;
        height: 36px !important;

    }

    .UserDetails .Custom_a.Li_a_Padding {
        padding: 20px 16px 13px !important;
    }

    .PlacedBtm {
        position: fixed;
        text-align: center;
        bottom: 0px;
    }

    .ClrRed_n {
        color: $primary_color !important;
    }

    .UserDetails {
        padding: 0 !important;

        .Custom_a {
            padding: 20px 16px 0 !important;

            .BorderBott {
                border-bottom: 1px solid #f1f1f1;
                padding-bottom: 20px;
                margin-bottom: 12px;
            }
        }
    }

    .UserName {
        width: 50px !important;
        font-family: $fontFamilyMedium !important;
        line-height: 16px;
    }

// }

// .hamrburg .fa.fa-bars{
//   font-size: 17px !important;
// }
// .Left_Menu {
//     font-size: 14px;
//     color: #444444;
// }

@media (max-width: 767px) {
    body {
        font-size: 14px !important;
    }
    // .Main_ABML_Money {
        .MainBg {
            padding-left: 0 !important;
        }
        .MainInnerFormWithoutFlex {
            margin: 16px;
        }

        .NewHeadOuter {
            margin: 16px;
        }

        .RouterContentOuter.collapsed {
            margin-left: 0 !important;
        }

        .RouterContentOuter {
            margin-left: 0 !important;
            padding: 16px 16px 72px 16px !important;
        }

        .navbar {
            height: 56px !important;
            padding: 0 16px !important;
        }

        .MarginTopAsHeader {
            margin-top: 56px !important;
        }

        .UserDetails_Mob {
            margin: 0 16px;
            padding: 16px 0;
            border-bottom: 1px solid #4444441a;
        }

        .UserImg {
            width: 38px !important;
            height: 38px !important;
            margin-right: 10px !important;
            border: 1px solid #ffffff;
        }

        .navbar.fixed-bottom {
            height: 56px !important;
            padding: 8px 6px 6px !important;
            border-top: 1px solid #fff;
            box-shadow: 2px 2px 6px rgb(0 0 0 / 20%);
        }

        .navbar.fixed-bottom .navbar-nav {
            // flex-direction: row !important;
            // justify-content: space-around;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 8px;
        }

        // .navbar .navbar-nav .nav-item {
        //     // margin-right: 15px;
        // }

        .navbar.fixed-bottom .navbar-nav li a {
            font-size: 11px !important;
            padding-top: 5px !important;
            line-height: 15px !important;
            // color: $primary_color;
            font-family: $fontFamilyMedium;
        }

        .navbar .navbar-nav {
            margin-left: 0px !important;
        }

        .navbar .navbar-nav .nav-item {
            margin-top: 0px !important;
        }

        .fixed-bottom.navbar .navbar-nav .nav-item .nav-link.active {
            color: $primary_color !important;
        }

        .AddImg {
            width: 30px !important;
            height: 30px !important;
            margin-top: 3px !important;
        }

        .UnderLineTabs .nav-tabs .nav-item .nav-link .TabImg.svg {
            width: 13px !important;
            height: 13px !important;
        }
        .OuterSection {
            margin-top: 24px !important;
        }
        .SubHeader {
            font-size: 14px;
        }
        .PieMainHead,
        .PieMainHead_2 {
            font-size: 12px !important;
        }
        .ColoredTabs .nav-tabs li a {
            padding-top: 0 !important;
        }
        .FormTemplate {
            padding: 16px !important;
        }
        .InputBoxOuter {
            margin-bottom: 16px !important;
        }
        // .CommonSubmitButton {
        //     margin: 0 0px 16px !important;
        // }
        .CommonSubmitButton.sm,
        .CommonSecondaryButton.sm {
            font-size: 14px !important;
            margin-left: 0;
        }
        .select2-container--default .select2-selection--single .select2-selection__rendered {
            font-size: 14px !important;
        }
        .has-float-label input:placeholder-shown:not(:focus)+*,
        .has-float-label select:placeholder-shown:not(:focus)+*,
        .has-float-label textarea:placeholder-shown:not(:focus)+* {
            font-size: 14px !important;
        }
        .SubmitMarginTop {
            text-align: inherit !important;
            display: flex;
            justify-content: space-around;
            .CommonSubmitButton {
                width: 50%;
                margin: 0 5px 16px 0 !important;
                // margin-right: 5px !important;
            }
            .CommonSubmitButton:last-child {
                margin-right: 0px !important;
            }
        }
        .InputBoxOuter .CheckAgree label {
            font-size: 14px !important;
        }
        .HorizontalRdbList {
            font-size: 14px !important;
        }
	.MarginBtm0{
            margin-bottom: 0 !important;
        }
        .MarginBtm16{
            margin-bottom: 16px !important;
        }
        .MarginTop{
            margin-top: 16px !important;
        }
        .MainDataLRPadding {
            padding: 0 16px !important; 
        }
        div.dataTables_wrapper div.dataTables_filter label {
            font-size: 12px !important;
        }
        div.dataTables_wrapper div.dataTables_info {
            font-size: 14px !important;
        }
        .LoginOuter {
            padding: 0 15px !important;
             .SubmitMarginTop {
                justify-content: center !important;
            }
        }
        .TopFilters .SearchBox .searchicon {
            width: 14px !important;
        }
        .SideMenuLinkOuter .multiselect-dropdown .dropdown-btn{
            font-size: 14px !important;
        }
        .SideMenuLinkOuter.AdjMultiselect .multiselect-item-checkbox input[type=checkbox]+div:before {
            width: 12px !important;
            height: 12px !important;
            margin-top: -8px !important;
        }
        .SideMenuLinkOuter.AdjMultiselect .multiselect-item-checkbox input[type=checkbox]+div{
            font-size: 14px !important;
        }
        .OthersMenuUl li a {
            font-size: 12px !important;
        }
        .CardOuter{
            border-bottom: 1px solid rgba(68, 68, 68, 0.1019607843);
            margin-bottom: 16px;
            padding-bottom: 16px;
        }
        .CardOuter:last-child {
            border-bottom: none;
            margin-bottom: 0px !important;
            padding-bottom: 0px !important;
        }
        .Card-0{
            margin: 0 0 8px;
            color: #000000;
            font-size: 16px;
            font-family: $fontFamilyMedium;
            display: flex;
            align-items: baseline;
            grid-gap: 10px;
        }
        .Card-1{
            display: flex;
            justify-content: flex-start;
            margin-bottom: 16px;
            font-size: 12px;
            color: rgba(69, 69, 69, 0.6509803922);
            font-family: $fontFamilyRegular;
        }
        .Card-2{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 12px;
        }
        .SinglePageOuter {
            padding: 16px !important;
        }
        .Dot{
            display: flex;
            align-items: center;
            position: relative;
            top: 8px;
        }
        .navbar .navbar-brand .logo {
            height: 38px !important;
        }
        .RightSideTabs .nav-tabs li a {
            font-size: 12px !important;
        }
        .ScrollTabsOuter {
            margin: 0px -10px 16px 0px !important;
        }
        .ScrollTabs::-webkit-scrollbar{
            display: none !important;
        }
        .ScrollTabs{
            overflow-y: auto;
            width: 100%;
        }
        .ScrollTabs .nav.nav-tabs {
            width: 405px;
            text-align: center;
            margin: auto;
            padding: 0;
        }
        .ScrollTabs .nav.nav-tabs .nav-item {
            width: auto;
            display: table-cell;
            position: relative;
            text-align: center;
            vertical-align: middle;
        }
        .ScrollTabsOuter{
            position: relative;
        }
        .ScrollTabsOuter:after {
            width: 32px;
            height: 30px;
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            // background: url("./assets/images/LightBg.png") no-repeat center center;
        }
        .ReportCard{
            background-color: #FFF;
            // padding: 16px;
            border: 1px solid #f2f2f2;
            border-radius: 5px;
            margin-bottom: 8px;
        }
        .Name{
            font-size: 13px;
            font-family: $fontFamilyMedium;
            margin-bottom: 4px;
        }
        .SubName{
            font-size: 11px;
            color: #999;
            font-family: $fontFamilyMedium;
            margin-top: 4px;
        }
        .Report-1{
            border-bottom: 1px solid #f2f2f2;
            // padding-bottom: 16px;
            padding: 12px 16px;
        }
        .Report-2{
            font-size: 11px;
            color: #999;
            font-family: $fontFamilyRegular;
            margin-top: 2px;
            padding-bottom: 16px;
        }
        .ReportDetails{
            display: grid;
            grid-template-columns: 1.1fr 1.1fr 1fr;
            grid-gap: 12px 12px;
            padding: 16px;
            color: #333;
            .GridTitle{
                font-size: 11px;
                font-family: $fontFamilyRegular;
                color: #999;
                margin-bottom: 2px;
            }
        }
        .GridValue{
            font-size: 12px;
            font-family: $fontFamilyRegular;
            color: #444444;
        }
        .DetailsValue{
            color: #6f6f74 !important;
        }
        .ReportChart{
            margin-top: 0px !important;
        }
       .Main_Alankit .SnapOuter{
            background: linear-gradient(277deg, #343a40bd 0%, #0275f3a1  80%) !important;
        }
        .navbar.fixed-bottom .navbar-nav li a {
            font-size: 9px!important;
            padding-top: 4px!important;
            line-height: 15px!important;
            // color: $primary_color;
            font-family: $fontFamilyMedium;
          }
        .slick-dots li {
            position: relative;
            display: inline-block;
            height: 20px;
            width: 14px !important;
            margin: 0 2px !important;
            padding: 0;
            cursor: pointer;
        }
        .slick-dots li button:before {
            font-size: 5px !important;
        }
        progress-bar .progress-inner {
            min-height: 3px !important;
            height: 3px !important;
        }
        .Summary-1 {
            font-size: 11px;
            font-family: $fontFamilyRegular;
            display: flex;
            color: #999;
        }
        
         .Dot {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin: 0 8px;
            top: 1px;
        }
         .Summary-1 i {
            font-size: 3px;
        }
         .SummaryDetails {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #333;
            padding: 16px;
        }
         .SummaryCard {
            border-bottom: 1px solid #f2f2f2;
            padding: 16px;
        }
        .Main_ABML_Money {
            .navbar.fixed-bottom .navbar-nav li a {
                font-size: 11px !important;
            }
            .QuickTransactAndCalcInner .QuickTransactAndCalcText{
                font-size: 13px !important;
            }
            .SubName {
                margin-top: -3px !important;
            }
        }
        .DashImg {
            width: 24px !important;
            height: 24px !important;
        }
}

.DigioClass {
    position: fixed !important;
}

.BgTransparent {
    background-color: transparent !important;
}

.UnderLineTabs .nav-tabs .nav-item .nav-link.active .TabImg.svg .st0 {
    fill: $primary_color !important;
}

.UnderLineTabs .nav-tabs .nav-item .nav-link.active .TabImg.svg .rect {
    fill: $primary_color!important;
}

.UnderLineTabs .nav-tabs .nav-item .nav-link .TabImg.svg {
    margin-right: 7px !important;
    width: 18px;
    height: 18px;
}

// .Main_ABML_Money .MainBg {
//     padding-left: 0px !important;
// }
.skeleton-loader {
    margin-bottom: 2px !important;
}

.PositiveValue {
    color: green !important;
}

.NagativeValue {
    color: red;
}

.ColoredTabs {
    .nav-tabs {
        border: none !important;

        .nav-link.active {
            border: none !important;
        }
    }
}

.ColoredTabs .nav-tabs li a {
    padding: 6px 0px 12px !important;
}

.ColoredTabs .nav-tabs li a:first-child {
    margin-left: 0 !important;
    margin-right: 12px !important;
}

.Filltabs {
    .nav-tabs {
        border: none !important;

        .nav-item {
            margin-right: 10px;

            .nav-link {
                color: #858585;
                border: 2px solid #e5e5e5 !important;
                border-radius: 4px;
                padding: 4px 11px;
                font-family: $fontFamilyRegular;
                cursor: pointer;
                font-size: 14px;
            }
        }
    }
}

.Filltabs .nav-tabs .nav-item .nav-link.active {
    border: 1px solid $secondary_color !important;
    background-color: $secondary_color !important;
    color: #fff;
    font-family: $fontFamilyRegular;
}

.Filltabs .nav-tabs li:last-child {
    margin-right: 0px !important;
}

 .CommonTable tbody tr td.FDMonthName {
    font-size: 16px !important;
    font-family: $fontFamilyMedium !important;

    .MonthState {
        margin-right: 25px;
        border: 1px solid #bdbdbd;
        border-radius: 25px;
        width: 75px;
        padding: 3px;
        display: inline-block;
        text-align: center;
        font-size: 14px;
        color: #bdbdbd;
    }
}

.PieMainHead {
    font-size: 14px;
    font-family: $fontFamilyMedium;
    fill: #000;
}

.PieMainHead_2 {
    font-size: 14px;
    font-family: $fontFamilyMedium;
    fill: #444;
}

ngb-modal-backdrop {
    z-index: 1050 !important;
}
.SideMenuLinkOuter .multiselect-dropdown .dropdown-btn{
    color: #949494 !important;
  }
.GenerateNewMandate{
    font-size: 12px;
    color: $secondary_color;
    font-family: $fontFamilyMedium;
    cursor: pointer;
}
.Disable{
    pointer-events: none;
    opacity: 0.5;
}
.readonly{
    pointer-events: none;
}
.progress-inner{
    line-height: 3;
}
.BorderRadius{
    border-radius: 7px; 
}
 .CommonTable tbody tr.TrTotal td {
    font-family: $fontFamilyBold !important;
    font-size: 14px;
}
.controlLoader{
    position: absolute;
    right: 5px;
    top: 5px;
    img{
        width: 30px;
        height: 30px;
    }
}
svg {
    #Primary{
        stroke: $primary_color !important;
    }
    #Secondary{
        stroke: $secondary_color !important;
    }
    #Primary_fill{
        fill: $primary_color !important;
    }
    #Secondary_fill{
        fill: $secondary_color !important;
    }
}
 .Sidemenu li.active a {
    color: $secondary_color !important;
    font-family: $fontFamilyMedium;
}
.navbar.fixed-bottom .navbar-nav .nav-item.active svg path{
    fill: $primary_color;
}

.datepicker table tr td span.active.active, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover.active, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active:hover:active {
    background-color: $primary_color !important;
}

// @media (min-width: 768px) {
    .Main_ABML_Money{
        // .LoginOuter .UnderLineTabs .nav-tabs {
        //     grid-template-columns: 1fr 1fr !important;
        // }
        .MarginTopAsHeader {
            margin-top: 100px !important;
        }
        .LoginOuter .MainOuter {
            margin: 130px auto 30px !important;
        }
        .navbar.fixed-top{
            // background-color: #c8142a !important;
            // background-color: #ca142a !important;
            // background: linear-gradient(90deg, rgba(255,255,255,0.145623215106355) 50%, rgba(200,20,42,1) 80%);
            padding-left: 0;
            background-image: url(./assets/images/NAVimageClearLogo.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            // background-color: #c8142a !important;
            .ABML_BG_White{
                background-color: #fff !important;
                padding: 6px 0;
                .hamrburg {
                    margin-left: 16px;
                }
                a{
                    margin-right: 0 !important;
                }
            }
            .container-fluid{
                padding: 0 !important;
                .navbar-brand.LogoAnchorClass{
                    padding: 6px 20px;
                    background-color: #fff;
                }
            }
            .logo {
                width: 145px;
                position: relative;
                right: 5px;
            }
        } 
        .MarginLR{
            margin: 0 20px !important;
        }
        .logo_img{
            width: 145px;
        }
    }
// }
.Second_Band {
    border-top: 1px solid #ccc;
    background: #fff;
    // position: relative;
    // z-index: -11;
    border-bottom: 1px solid #ccc;
    top: 0px;
    padding: 8px 0;
    font-size: 18px;
    line-height: 20px;
    padding-left: 64px;
    color: #ca142a;
    font-family: $fontFamilyMedium !important;
    margin-top: 62px;
    transition: all 0.5s;
  }  
  .sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%
  }
  .hidden {
    display: none;
    // transition: all 0.5s;
  }
  .FORScroll.MarginTopAfterScroll {
    margin-top: 62px !important;
    // transition: all 0.5s;
  }
@media (max-width: 768px) {
    .Main_ABML_Money{
        .navbar.fixed-top{
            background-position: -66px;
            .ABML_BG_White{
                background-color: #fff !important;
                padding: 6px 0;
                .hamrburg {
                    margin-left: 16px;
                }
                a{
                    margin-right: 0 !important;
                }
            }
            .logo {
                width: 145px;
                position: relative;
                right: 5px;
            }
        } 
        .navbar.fixed-top.PostHeaderMobile{
            background-image: none !important
        }
        .MarginLR {
            margin: 0px !important;
        }
        .navbar.fixed-top .container-fluid .navbar-brand.LogoAnchorClass {
            padding: 0 5px;
            background-color: #fff;
        }
        .logo_img {
            width: 115px !important;
        }
        
    }
}


.datepicker.dropdown-menu {
    position: absolute;
    margin-top: 100px;
 top:246px ;
    left: 0;
    z-index: 10000000 !important;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 5px;
    // margin: 3px 0 0;
    list-style: none;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}