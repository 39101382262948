    $primary_color : var(--primary-color);
    $secondary_color : var(--secondary-color);
    $third_color : var(--third-color);
    $body_color : var(--body-color);
    $body_color_gray : #9b9b9b;
    $body_bg_color : var(--body-bg-color);
    $body_footer_bg : var(--body-footer-bg-color);
    $Table_Header_Bg :  var(--table-header-bg-color);
    $Table_Head_Text :var(--table-header-text-color);
    $fontFamilyRegular : var(--font-family-regular);
    $fontFamilyMedium : var(--font-family-semibold);
    $fontFamilyBold : var(--font-family-bold);
    $PrimaryColor:#1E467A;
    $Primarytextcolor:#fff;
    $SecondaryColor:#ED3237 ;
    $gray:#696969;
